import React, { useState } from "react";
import { Container, Row, Col, Form, InputGroup, Button, Spinner } from "react-bootstrap";
import { AiOutlineMail, AiOutlineLock, AiOutlineMobile } from "react-icons/ai";
import { FiUser } from "react-icons/fi";
import { GoOrganization } from "react-icons/go";
import TopNav from "../Home/TopNav";
import Footer from "../Home/Footer";
import ApiHelper from "../../utils/ApiHelper";
import DialogBox from "../Common/DialogBox";
import { useNavigate } from "react-router-dom";

const Registration = () => {
  const [registerData, setregisterData] = useState({
    firstName: "",
    lastName: "",
    contactNo: 0,
    email: "",
    password: "",
    confPassword: "",
    companyName: "",
    isShown: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [dialogBox, setDialogBox] = useState({
    status: false,
    message: "",
  });
  const history = useNavigate();
  const [errors, setErrors] = useState("");
  const onInputChange = (e) => {
    setregisterData({ ...registerData, [e.target.name]: e.target.value });
  };
  const validConfPassword = (e) => {
    let confPassword = e.target.value;
    if (registerData.password !== e.target.value) {
      return setErrors({
        ...errors,
        confPassword: "Password And Confirm Password Should Be Same",
      });
    } else {
      return setErrors({ ...errors, confPassword: "" });
    }
  };

  const validEmail = (e) => {
    let email = e.target.value;
    const validEmail = RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );
    return validEmail.test(email)
      ? setErrors({ ...errors, email: "" })
      : setErrors({ ...errors, email: "Please Enter Valid Email" });
  };
  const validateForm = () => {
    let formIsValid = true;
    let error = {};
    const validEmail = RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );

    if (registerData.firstName === "") {
      formIsValid = false;
      error["firstName"] = "Please Enter First Name";
    }
    if (registerData.lastName === "") {
      formIsValid = false;
      error["lastName"] = "Please Enter Last Name";
    }
    if (registerData.contactNo === 0 || registerData.contactNo === "") {
      formIsValid = false;
      error["contactNo"] = "Please Enter Contact No";
    }
    if (!validEmail.test(registerData.email)) {
      formIsValid = false;
      error["email"] = "Please Enter Valid Email";
    }
    if (registerData.email === "") {
      formIsValid = false;
      error["email"] = "Please Enter Email Id";
    }
    if (registerData.password === "") {
      formIsValid = false;
      error["password"] = "Please Enter Password";
    }
    if (registerData.confPassword === "") {
      formIsValid = false;
      error["confPassword"] = "Please Enter Confirm Password";
    }
    if (registerData.password !== registerData.confPassword) {
      formIsValid = false;
      error["confPassword"] = "Password And Confirm Password Should Be Same";
    }

    setErrors(error);
    return formIsValid;
  };
  const handleRegister = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true)
      let current = new Date();
      let date = `${current.getFullYear()}-0${
        current.getMonth() + 1
      }-${current.getDate()}`;

      const records = {
        firstName: registerData.firstName,
        lastName: registerData.lastName,
        email: registerData.email,
        contactNo: registerData.contactNo,
        password: registerData.password,

        role: ["user"],
        cr_date: date,
        companyName: registerData.companyName,
        isVerified: true,
      };

      const customerRegister = await ApiHelper.customerRegister(records);
      if (customerRegister.message === "User registered successfully!") {
        setDialogBox({
          ...dialogBox,
          status: true,
          message:
            // "Thank you for registering with Shiplords. We have sent you an email for verification. Please check your spam folder if you do not see the verification email in your inbox.",
            "You have succcesfully registered to the AIETPL",
        });
      } else {
        if (customerRegister.message === "Error: Contact No is already exist!") {
          setErrors({ ...errors, contactNo: customerRegister.message });
          // setDialogBox({
          //   ...dialogBox,
          //   status: true,
          //   message:
          //     // "Thank you for registering with Shiplords. We have sent you an email for verification. Please check your spam folder if you do not see the verification email in your inbox.",
          //     "You have succcesfully registered to the AIETPL",
          // });
        }
        if (customerRegister.message === "Error: Email is already in use!") {
          setErrors({ ...errors, email: customerRegister.message });
          
        }

        //   setDialogBox({
        //     ...dialogBox,
        //     status: true,
        //     message:
        //       // "Thank you for registering with Shiplords. We have sent you an email for verification. Please check your spam folder if you do not see the verification email in your inbox.",
        //       customerRegister.message
        //   });
      }
      setIsLoading(false);
    }
  };
  const closeModal = () => {
    setDialogBox({ ...dialogBox, status: false, message: "" });
    history("/login");
  };
  return (
    <div>
      <TopNav />
      <div className="login-bg">
        <Container>
          <Row>
            <Col sm={8}>
              <div className="login-form animate__animated animate__bounceInLeft">
                <div className="pt-4 pb-4">
                  <h2 className="login-heading">
                    Register Here to Create Account
                  </h2>
                </div>
                <Form className="row px-5" onSubmit={handleRegister}>
                  <Form.Group
                    className="col-sm-6 mb-3"
                    controlId="formGroupEmail"
                  >
                    <Form.Label>First Name</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <FiUser className="login-icons" />
                      </InputGroup.Text>
                      <Form.Control
                        placeholder="Enter Your First Name"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        onChange={(e) => {
                          onInputChange(e);
                          setErrors({ ...errors, firstName: "" });
                        }}
                        name="firstName"
                        value={registerData.firstName}
                      />
                    </InputGroup>
                    {errors.firstName && (
                      <span style={{ color: "red" }}>{errors.firstName}</span>
                    )}
                  </Form.Group>
                  <Form.Group
                    className="col-sm-6 mb-3"
                    controlId="formGroupPassword"
                  >
                    <Form.Label>Last Name</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <FiUser className="login-icons" />
                      </InputGroup.Text>
                      <Form.Control
                        placeholder="Enter Your Last Name"
                        aria-label="Password"
                        aria-describedby="basic-addon1"
                        onChange={(e) => {
                          onInputChange(e);
                          setErrors({ ...errors, lastName: "" });
                        }}
                        name="lastName"
                        value={registerData.lastName}
                      />
                    </InputGroup>
                    {errors.lastName && (
                      <span style={{ color: "red" }}>{errors.lastName}</span>
                    )}
                  </Form.Group>
                  <Form.Group
                    className="col-sm-6 mb-3"
                    controlId="formGroupEmail"
                  >
                    <Form.Label>Contact Number</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <AiOutlineMobile className="login-icons" />
                      </InputGroup.Text>
                      <Form.Control
                        min="0"
                        placeholder="Enter Number"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        onChange={(e) => {
                          onInputChange(e);
                          setErrors({ ...errors, contactNo: "" });
                        }}
                        name="contactNo"
                        value={registerData.contactNo}
                      />
                    </InputGroup>
                    {errors.contactNo && (
                      <span style={{ color: "red" }}>{errors.contactNo}</span>
                    )}
                  </Form.Group>
                  <Form.Group
                    className="col-sm-6 mb-3"
                    controlId="formGroupPassword"
                  >
                    <Form.Label>Email</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <AiOutlineMail className="login-icons" />
                      </InputGroup.Text>
                      <Form.Control
                        placeholder="Enter Your Email"
                        aria-label="Password"
                        aria-describedby="basic-addon1"
                        onChange={(e) => {
                          onInputChange(e);
                          validEmail(e);
                          // setErrors({ ...errors, email: "" });
                        }}
                        name="email"
                        value={registerData.email}
                      />
                    </InputGroup>
                    {errors.email && (
                      <span style={{ color: "red" }}>{errors.email}</span>
                    )}
                  </Form.Group>
                  <Form.Group
                    className="col-sm-6 mb-3"
                    controlId="formGroupPassword"
                  >
                    <Form.Label>Password</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <AiOutlineLock className="login-icons" />
                      </InputGroup.Text>
                      <Form.Control
                        type={registerData.isShown ? "text" : "password"}
                        placeholder="Enter Password"
                        aria-label="Password"
                        aria-describedby="basic-addon1"
                        onChange={(e) => {
                          onInputChange(e);
                          setErrors({ ...errors, password: "" });
                        }}
                        name="password"
                        value={registerData.password}
                      />
                    </InputGroup>
                    {errors.password && (
                      <span style={{ color: "red" }}>{errors.password}</span>
                    )}
                  </Form.Group>
                  <Form.Group
                    className="col-sm-6 mb-3"
                    controlId="formGroupPassword"
                  >
                    <Form.Label>Confirm Password</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <AiOutlineLock className="login-icons" />
                      </InputGroup.Text>
                      <Form.Control
                        type={registerData.isShown ? "text" : "password"}
                        placeholder="Enter Password"
                        aria-label="Password"
                        aria-describedby="basic-addon1"
                        onChange={(e) => {
                          onInputChange(e);
                          validConfPassword(e);
                          // setErrors({ ...errors, confPassword: "" });
                        }}
                        name="confPassword"
                        value={registerData.confPassword}
                      />
                      {/* <Box> */}

                      {/* </Box> */}
                    </InputGroup>
                    {errors.confPassword && (
                      <span style={{ color: "red" }}>
                        {errors.confPassword}
                      </span>
                    )}
                  </Form.Group>
                  <Form.Group
                    className="col-sm-12 mb-3"
                    controlId="formGroupPassword"
                  >
                    <Form.Check
                      label="Show Password"
                      aria-label="option 1"
                      type="checkbox"
                      checked={registerData.isShown}
                      onChange={() =>
                        setregisterData({
                          ...registerData,
                          isShown: !registerData.isShown,
                        })
                      }
                    />
                  </Form.Group>
                  <Form.Group
                    className="col-sm-6 mb-3"
                    controlId="formGroupPassword"
                  >
                    <Form.Label>Company Name</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <GoOrganization className="login-icons" />
                      </InputGroup.Text>
                      <Form.Control
                        placeholder="Enter Company Name"
                        aria-label="Password"
                        aria-describedby="basic-addon1"
                        onChange={onInputChange}
                        name="companyName"
                        value={registerData.companyName}
                      />
                    </InputGroup>
                  </Form.Group>
                  {/* <div>
        <NavLink  to={"/login"}>
            <p className='d-flex justify-content-end fgps-text'>Already Have an Account?</p>
        </NavLink>
      </div> */}
                  <div className="pb-3">
                    <Button className="login-btn" type="submit">
                    {isLoading && <Spinner />}
                      SIGN UP
                    </Button>
                  </div>
                  <div className="text-center pb-3">
                    <p className="p-info-white">
                      Already Have an Account?{" "}
                      <a href="/login" className="p-info-orange">
                        Login Here!
                      </a>{" "}
                    </p>
                  </div>
                </Form>
              </div>
            </Col>
            {/* <Col sm={6} className='text-center'>
                    <div className='animate__animated animate__bounceInRight'>
                        <img className='img-fluid login-img' src={login} alt="login-illustration" />
                    </div>
                
                </Col> */}
          </Row>
        </Container>
      </div>

      <Footer />
      {dialogBox.status && (
        <DialogBox
          message={dialogBox.message}
          closeMessage="OK"
          closeModal={closeModal}
        />
      )}
    </div>
  );
};

export default Registration;
