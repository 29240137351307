



export const logindetail=(data)=>{
    return{
        type:"LOGINDETAIL",
        data:data
    }

}

export const cartdetail=(data)=>{
    return{
        type:"CARTDETAIL",
        data:data
    }
    
}
export const cartcount=(cartCount)=>{
    return{
        type:"CARTCOUNT",
        cartCount:cartCount
    }
    
}
export const updatecartquantity=(index,quantity)=>{
    return{
        type:"UPDATECARTQUANTITY",
        index:index,
        quantity:quantity
    }
    
}
export const updatecartrequirement=(index,detailedRequirement)=>{
    return{
        type:"UPDATECARTREQUIREMENT",
        index:index,
        detailedRequirement:detailedRequirement
    }
    
}
export const updateQunatityTempCartData=(index,quantity)=>{
    return{
        type:"UPDATETEMPCARTQUANTITY",
        index:index,
        quantity:quantity
    }
    
}
export const tempCartData=(tempdata)=>{
    return{
        type:"TEMPCARTDETAIL",
        tempdata:tempdata
    }
}
export const resetCartItem=()=>{
    return{
        type:"RESETCART"
    }
}
export const removeCartData=(cartIds)=>{
    return{
        type:"REMOVECARTITEM",
        cartIds:cartIds
    }
}
export const removeTempCartData=(index)=>{
    return{
        type:"REMOVETEMPCARTITEM",
        index:index
    }
}

export const updateCountByUser=(count)=>{
    return{
        type:"UPDATECOUNTBYUSER",
        count:count
    }
}





    

