import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
  
  } from "@mui/material";
  import { useState } from "react";
  

  const DeleteBox = (props) => {
    const { closeMessage, closeModal, deleteRecord,message,successMessage } = props;
    const [open, setOpen] = useState(true);
  
    const handleClose = () => {
      setOpen(false);
      closeModal && closeModal();
    };
  
    const handleDelete = () => {
      setOpen(false);
      deleteRecord && deleteRecord();
    };
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent >
          <DialogContentText id="alert-dialog-description" >
            
          <span className="font-bold">
            {/* Are you sure you want to remove this Equipment from your enquiry cart? */}
            {message}
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="flex gap-x-4 pr-2 text-center"  >
         {successMessage?.toUpperCase()==="Delete".toUpperCase()?
          <Button onClick={handleDelete} className="btn-next mr-3">{successMessage}</Button>:
          <Button onClick={handleDelete} className="btn-primary">{successMessage}</Button>}
          <Button onClick={handleClose} className="btn-grey">{closeMessage}</Button>
       
        </DialogActions>
      </Dialog>
    );
  };
  
  export default DeleteBox;
  