import { combineReducers } from 'redux';

import loginreducer from './loginreducer'
import cartreducer from './cartreducer'

const reducers = combineReducers({
	
	loginreducer,
	cartreducer
	
});

// const persistedReducer = persistReducer(persistConfig, reducers);
export default reducers;
