import TopNav from "../Home/TopNav";
import SideNav from "../Sidebar/SideNav";
import { Form, Spinner } from "react-bootstrap";
import {
  Box,
  Button,
  Grid,
  Stack,
  TextareaAutosize,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Container,
} from "@mui/material";
import ReactHtmlParser from "html-react-parser";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import AddLinkIcon from "@mui/icons-material/AddLink";
import { useEffect, useState } from "react";

import DialogBox from "../Common/DialogBox";

import OutlinedInput from "@mui/material/OutlinedInput";
import { useSelector } from "react-redux";
import ApiHelper from "../../utils/ApiHelper";
import { useNavigate, useParams } from "react-router-dom";
import { baseUrlAietplBackend, baseUrlAietplImage, baseUrlAietplThumbnailImage } from "../../utils/ApiUtils";

const ProductEnquiryReplies = (props) => {
  const params = useParams();
  const history = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const logindata = useSelector((state) => state.loginreducer);
  const [enquiryDetail, setenquiryDetail] = useState([]);
  const [dialogBox, setDialogBox] = useState(false);

  const [data, setdata] = useState({
    message: "",
    productMessage: [],
    attachment: {},
    buttonMessage: "SEND REPLY",
    arrachment: "",
    replyMessage: "",
    addDiscount: false,
    discountPrice: [],
  });
  const [addDiscount, setAddDiscount] = useState([]);

  const [productPrice, setProductPrice] = useState([]);
  const [errMessage, setErrMessage] = useState("");
  useEffect(() => {
    window.scroll(0, 0);

    if (logindata.data.length !== 0) {
      
      setIsLoading(true);
      ApiHelper.getproductEnquiryByEnquiryId(params.id,logindata.data.accessToken).then((res) => {
        setenquiryDetail(res);
        console.log(res);
        setIsLoading(false);
        const initialPrice = res?.productEnquiryDetails.map(
          (items) => items.productMaster?.productPrice
        );
        console.log(initialPrice);
        setProductPrice(initialPrice);
        const initialStateOfDiscount = res?.productEnquiryDetails.map(
          (items) => false
        );
        setAddDiscount(initialStateOfDiscount);
      });
     
    }
  }, []);
  const closeModal = () => {
    setDialogBox(false);
    history("/product-enquiry-list");
  };
  const sendReply = async (e) => {
    e.preventDefault();

    if (
      data.attachment &&
      data.attachment.size > 0 &&
      data.attachment !== null
    ) {
      let formData2 = new FormData();
      formData2.append("pdfFile", data.attachment);
      setdata({ ...data, buttonMessage: "Sending..." });
      const pdfFileData = await ApiHelper.addPdfFile(formData2,logindata.data.accessToken);
      let attachrecord = [];
      let emailarr = [];
      let notifyArr = [];
      for (let i = 0; i < enquiryDetail.productEnquiryDetails.length; i++) {
        let discountPrice = 0;
        if (data.discountPrice[i]) {
          discountPrice = Number(data.discountPrice[i]);
        }
        let detailObj = {
          productEnquiryDetailId:
            enquiryDetail.productEnquiryDetails[i]?.productEnquiryDetailId,
          replyProductMessage: data.productMessage[i],
          replied: true,
          enquiryProductPrice: Number(productPrice[i]),
          replyMessage: data.message,
          attachment: pdfFileData?.pdfName,
          discountPrice: discountPrice,
        };

        attachrecord.push(detailObj);
        const emailRecord = {
          productName:
            enquiryDetail.productEnquiryDetails[i]?.productMaster.productName,
          enquiryNo: enquiryDetail.enquiryNo,
          replyMessage: data.message,
          replyProductMessage: data.productMessage[i],
          fileName: pdfFileData?.pdfName,
          emailId: enquiryDetail.emailId,
        };
        emailarr.push(emailRecord);
      }
      const requestData = {
        productEnquiryId: enquiryDetail.productEnquiryId, // Replace with the actual enquiry ID
        isReplied: true,
        detailUpdates: attachrecord,
      };

      await ApiHelper.replyProductEnquiry(requestData,logindata.data.accessToken);

      setdata({ ...data, buttonMessage: "Sent" });
      setDialogBox(true);
      await ApiHelper.productEnquiryReplyMail(emailarr,logindata.data.accessToken);
      // await ApiHelper.postEnquiryNotification(notifyArr);
    } else {
      let record = [];
      let notifyArr = [];
      let emailarr = [];
      for (let i = 0; i < enquiryDetail.productEnquiryDetails.length; i++) {
        let discountPrice = 0;
        if (data.discountPrice[i]) {
          discountPrice = Number(data.discountPrice[i]);
        }
        let detailObj = {
          productEnquiryDetailId:
            enquiryDetail.productEnquiryDetails[i]?.productEnquiryDetailId,
          replyProductMessage: data.productMessage[i],
          replied: true,
          enquiryProductPrice: Number(productPrice[i]),
          replyMessage: data.message,
          discountPrice: discountPrice,
        };

        record.push(detailObj);
        const emailRecord = {
          productName:
            enquiryDetail.productEnquiryDetails[i]?.productMaster.productName,
          enquiryNo: enquiryDetail.enquiryNo,
          replyMessage: data.message,
          replyProductMessage: data.productMessage[i],

          emailId: enquiryDetail.emailId,
        };
        emailarr.push(emailRecord);
      }
      const requestData = {
        productEnquiryId: enquiryDetail.productEnquiryId, // Replace with the actual enquiry ID
        replyMessage: data.message,
        detailUpdates: record,
      };
      setdata({ ...data, buttonMessage: "Sending..." });
      await ApiHelper.replyProductEnquiry(requestData,logindata.data.accessToken);

      setdata({ ...data, buttonMessage: "Sent" });
      setDialogBox(true);
      await ApiHelper.productEnquiryReplyMail(emailarr,logindata.data.accessToken);
      // await ApiHelper.postEnquiryNotification(notifyArr);
    }
  };

  const inputChange = (e) => {
    setdata({ ...data, [e.target.name]: e.target.value });
  };
  const handleFileInputChange = (e) => {
    const selectedFile = e.target.files && e.target.files[0];

    if (selectedFile) {
      // Process the selected PDF file
      setdata({ ...data, attachment: selectedFile });
      console.log("Selected PDF file:", selectedFile.size);
    }
  };
  const handleAddAttachmentClick = () => {
    // Programmatically trigger file input click

    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = ".pdf"; // Only allow PDF files
    fileInput.addEventListener("change", handleFileInputChange);
    fileInput.click();
  };
  // replyProductEnquiry
  const handlePriceChange = (e, index) => {
    const updatedPrice = [...productPrice];
    updatedPrice[index] = e.target.value;
    setProductPrice(updatedPrice);
  };
  const NumberSeperator = (input) => {
    return new Intl.NumberFormat("en-IN", { minimumFractionDigits: 2 }).format(
      input
    );
  };
  const handleDiscountPriceChange = (e, index) => {
    const updatedPrice = [...data.discountPrice];
    updatedPrice[index] = e.target.value;
    setdata({ ...data, discountPrice: updatedPrice });
    console.log(updatedPrice);
  };
  return (
    <>
      <SideNav />
      {isLoading ? (
        <>
          {logindata.data?.roles[0] === "ROLE_ADMIN" && <SideNav />}
          {logindata.data?.roles[0] === "ROLE_USER" && <TopNav />}
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "50vh",
            }}
          >
            <Spinner />
          </span>
        </>
      ) : (
        <div className="content-wrapper">
          <Container className="py-4">
            <Box className="box mt-5">
              <h2 className="form-heading">
                To: {enquiryDetail && enquiryDetail?.fullName}
              </h2>
              {logindata.data.length !== 0 && (
                // logindata.data?.roles[0] === "ROLE_ADMIN" &&
                <Box className="box-2">
                  {enquiryDetail?.productEnquiryDetails?.map(
                    (detail, index) => (
                      <Grid container key={index} className="box">
                        {/* <Grid item md={2} sm={3} xs={12} className="p-2">
                        <img
                          src={
                            baseUrlAietplBackend +"/"+
                            detail.productMaster.fileName1
                          }
                          width={100}
                          height={100}
                          alt="product"
                          className="max-w-sm rounded border bg-white p-1 flex"
                        /> <h5 className="pt-1">
                        {" "}
                        {
                          detail.productMaster.productName
                        }
                      </h5>
                      </Grid> */}
                        <Grid item md={12} sm={12} xs={12}>
                          <Table id="modal-table">
                            <TableHead>
                              <TableRow>
                                <TableCell width={"25%"}>Equipment </TableCell>
                                <TableCell width={"5%"}>Quantity</TableCell>
                                <TableCell width={"15%"}>Price</TableCell>
                                <TableCell width={"15%"}>Total</TableCell>
                                <TableCell>Your Best Price</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell>
                                  <div className="discount">
                                    {detail.productMaster.thumbnailFilenames[0] && (
                                      <img
                                        src={
                                          baseUrlAietplThumbnailImage +
                                          "/" +
                                          detail.productMaster.thumbnailFilenames[0]
                                        }
                                        width={60}
                                        height={60}
                                        alt="product"
                                        className="max-w-sm rounded border bg-white p-1 flex"
                                      />
                                    )}{" "}
                                    <h5 className="pt-1">
                                      {" "}
                                      {detail.productMaster?.productName}
                                    </h5>
                                  </div>{" "}
                                </TableCell>
                                <TableCell>{detail.productQuantity}</TableCell>
                                <TableCell>
                                  {detail.replied === false ? (
                                    <OutlinedInput
                                      // id="outlined-basic"
                                      // className="css-tzsjye"
                                      placeholder={
                                        productPrice[index] === 0
                                          ? "Please enter price per piece"
                                          : ""
                                      }
                                      required
                                      name="productPrice"
                                      className="txt-field-mui"
                                      size="small"
                                      sx={{ width: "80px" }}
                                      value={
                                        productPrice[index] === 0
                                          ? ""
                                          : productPrice[index]
                                      }
                                      onChange={(e) =>
                                        handlePriceChange(e, index)
                                      }
                                    />
                                  ) : (
                                    <TextField
                                      // id="outlined-basic"
                                      // className="css-tzsjye"

                                      required
                                      name="productPrice"
                                      variant="outlined"
                                      className="txt-field-mui"
                                      size="small"
                                      sx={{ width: "80px" }}
                                      value={detail.enquiryProductPrice}
                                      inputProps={{
                                        readOnly: true, // Ensure that the browser's validation also prevents negative values
                                        // step: 1, // Adjust the step as needed for decimal values
                                      }}
                                    />
                                  )}
                                </TableCell>
                                <TableCell>
                                  <span>₹</span>
                                  {detail.replied === false
                                    ? NumberSeperator(
                                        productPrice[index] *
                                          detail.productQuantity
                                      )
                                    : NumberSeperator(
                                        detail.enquiryProductPrice *
                                          detail.productQuantity
                                      )}
                                  /-
                                  {/* /
                                {
                                  detail.equipmentProductParameters
                                    .marineEquipmentProductMasterModel.unitMasterModel
                                    .unitName
                                } */}
                                </TableCell>
                                <TableCell>
                                  <div className="discount">
                                    {detail.discountPrice === false ||
                                    detail.replied === false ? (
                                      <>
                                        <Button
                                          onClick={() => {
                                            const updatedAddDiscount = [
                                              ...addDiscount,
                                            ];
                                            updatedAddDiscount[index] =
                                              !updatedAddDiscount[index];
                                            setAddDiscount(updatedAddDiscount);
                                          }}
                                          variant="outlined"
                                          className="addproducts"
                                        >
                                          ADD BEST PRICE
                                        </Button>{" "}
                                        <Tooltip title="The final lumpsum price you are ready to offer the customer.">
                                          <InfoOutlinedIcon />
                                        </Tooltip>
                                      </>
                                    ) : (
                                      <span> </span>
                                    )}{" "}
                                    <div className="row pt-2">
                                      <div className="col-sm-6">
                                        {addDiscount[index] && (
                                          <Form.Control
                                            // id="outlined-basic"
                                            // className="css-tzsjye"
                                            placeholder="Enter discount Amount"
                                            required
                                            name="discountPrice"
                                            size="small"
                                            className="txt-field-mui col-sm-3"
                                            value={data.discountPrice[index]}
                                            onChange={(e) =>
                                              handleDiscountPriceChange(
                                                e,
                                                index
                                              )
                                            }
                                          />
                                        )}
                                      </div>
                                    </div>
                                    {detail.replied === true &&
                                      (detail.discountPrice === 0 ? (
                                        <span>NA</span>
                                      ) : (
                                        <span>
                                          <span>₹</span>
                                          {NumberSeperator(
                                            detail.discountPrice
                                          )}
                                          /-
                                          {/* /
                                      {
                                        detail.equipmentProductParameters
                                          .marineEquipmentProductMasterModel
                                          .unitMasterModel.unitName
                                      } */}
                                        </span>
                                      ))}
                                  </div>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell colSpan={5}>
                                  <h6>Detailed Requirement: </h6>
                                  <p>
                                    {detail?.detailedRequirement && (
                                      <Box className="p_desc">
                                        {ReactHtmlParser(
                                          detail?.detailedRequirement
                                        )}
                                        {/* {ReactHtmlParser(detail.enquiryText)} */}
                                      </Box>
                                    )}
                                    {/* {detail.enquiryText} */}
                                  </p>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell colSpan={5}>
                                  <Form.Label>
                                    Write your response here
                                  </Form.Label>
                                  {detail.replied === false ? (
                                    <Grid
                                      item
                                      md={12}
                                      sm={12}
                                      xs={12}
                                      className="p-2 "
                                    >
                                      <Form.Control
                                        className="txt-field-l"
                                        required
                                        name="custMessage"
                                        minRows={1}
                                        placeholder="Please enter your response about this equipment, if any "
                                        onChange={(e) => {
                                          const newData = { ...data };
                                          newData.productMessage[index] =
                                            e.target.value;
                                          setdata(newData);
                                        }}
                                      />
                                    </Grid>
                                  ) : (
                                    <Grid
                                      item
                                      md={12}
                                      sm={12}
                                      xs={12}
                                      className="p-2 "
                                    >
                                      <TextareaAutosize
                                        className="txt-field-msg"
                                        required
                                        name="custMessage"
                                        value={detail.replyProductMessage}
                                        minRows={1}
                                        readOnly={true}
                                      />
                                    </Grid>
                                  )}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                          {/* <p>Quantity:{detail.quantity}</p>
  
                        <p>
                          Price:
                          {
                            detail.equipmentProductParameters
                              .marineEquipmentProductMasterModel.eqProductPrice
                          }
                        </p> 
                        <div className="flex justify-around">
                          Price:
                          {detail.replied === false ? (
                            <TextField
                           
                            placeholder="Please enter price per piece"
                              required
                              name="productPrice"
                              variant="outlined"
                              className="txt-field-mui"
                              value={productPrice[index]}
                              onChange={(e: any) => handlePriceChange(e, index)}
                            />
                          ) : (
                            <TextField
                             
  
                              required
                              name="productPrice"
                              variant="outlined"
                              className="txt-field-mui"
                              value={detail.enquiryProductPrice}
                              inputProps={{
                                readOnly: true, // Ensure that the browser's validation also prevents negative values
                                // step: 1, // Adjust the step as needed for decimal values
                              }}
                            />
                          )}
                        
                          Total: <span>₹</span>
                          {detail.replied === false
                            ? NumberSeperator(
                                productPrice[index] * detail.quantity
                              )
                            : NumberSeperator(
                                detail.enquiryProductPrice * detail.quantity
                              )}
                          /
                          {
                            detail.equipmentProductParameters
                              .marineEquipmentProductMasterModel.unitMasterModel
                              .unitName
                          }
                          &nbsp;&nbsp;&nbsp;{" "}
                          {detail.discountPrice === false || detail.replied === false ?
                          <button
                            
                              onClick={() => {
                                const updatedAddDiscount = [...addDiscount];
                                updatedAddDiscount[index] = !updatedAddDiscount[index];
                                setAddDiscount(updatedAddDiscount);
                              }}
                            
                          >
                            ADD DISCOUNT
                          </button>:
                          
                         <span> Discount Price:</span>}
                          &nbsp;&nbsp;&nbsp;{" "}
                          {addDiscount[index] &&  (
                            <TextField
                             
                              placeholder="Enter discount price"
                              required
                              name="discountPrice"
                              variant="outlined"
                              className="txt-field-l "
                              value={data.discountPrice[index]}
                              onChange={(e: any) => handleDiscountPriceChange(e, index)}
                            />
                          )}
                           {detail.replied === true &&  (
                            <span>
                            {NumberSeperator(detail.discountPrice)}/
                              {detail.equipmentProductParameters
                                .marineEquipmentProductMasterModel.unitMasterModel
                                .unitName}
  
  
                                </span>
                            
                          )}
                        </div>
  */}
                        </Grid>

                        {/* {detail.replied === false ? (
                        <Grid item md={12} sm={12} xs={12} className="p-2 ">
                          <TextareaAutosize
                            className="txt-field-l"
                            required
                            name="custMessage"
                            minRows={1}
                            placeholder="Please enter your response about this product, if any "
                            onChange={(e: any) => {
                              const newData: any = { ...data };
                              newData.productMessage[index] = e.target.value;
                              setdata(newData);
                            }}
                          />
                        </Grid>
                      ) : (
                        <Grid item md={12} sm={12} xs={12} className="p-2 ">
                          <TextareaAutosize
                            className="txt-field-l"
                            required
                            name="custMessage"
                            value={detail.replyProductMessage}
                            minRows={1}
                            
                            readOnly={true}
                          />
                        </Grid>
                      )} */}
                      </Grid>
                    )
                  )}
                </Box>
              )}
              <br />
              {enquiryDetail.description && (
                <h2 className="form-heading">
                  Special Instructions By Customer
                </h2>
              )}
              {enquiryDetail.description}
              <div className="row">
                <Form.Label className="text-sm">
                  Add Special Instructions here :
                </Form.Label>
                <div className="col-sm-6">
                  {logindata.data.length !== 0 &&
                    // logindata.data?.roles[0] === "ROLE_ADMIN" &&
                    (enquiryDetail?.replied === false ? (
                      <Form.Control
                        className="txt-field-l"
                        required
                        // name="custMessage"
                        minRows={1}
                        placeholder="Optional"
                        name="message"
                        value={data.message}
                        onChange={inputChange}
                      />
                    ) : (
                      <Form.Control
                        className="txt-field-msg"
                        required
                        // name="custMessage"
                        minRows={1}
                        name="message"
                        value={enquiryDetail.replyMessage}
                        readOnly={true}
                      />
                    ))}
                </div>
              </div>
              <br />
              <Stack
                direction={{ xs: "column", md: "row", sm: "row" }}
                justifyContent={"space-between"}
                className="py-1"
                alignItems={"center"}
              >
                {logindata.data.length !== 0 &&
                  // logindata.data?.roles[0] === "ROLE_ADMIN" &&
                  enquiryDetail?.replied === false && (
                    <Button
                      variant="outlined"
                      className="sign-in_other"
                      onClick={() => {
                        handleAddAttachmentClick();
                        setErrMessage("");
                      }}
                    >
                      <AddLinkIcon />
                      Add Attachment
                    </Button>
                  )}
                {logindata.data.length !== 0 &&
                  enquiryDetail?.replied === true &&
                  enquiryDetail.attachment && (
                    <Button
                      variant="outlined"
                      className="sign-in_other"
                      onClick={() => {
                        window.open(
                          baseUrlAietplImage +
                            "/getPdf/" +
                            enquiryDetail.attachment
                        );
                      }}
                    >
                      <AddLinkIcon />
                      View Attachment
                    </Button>
                  )}
                {data?.attachment && data?.attachment?.name}
                {logindata.data.length !== 0 &&
                  // logindata.data?.roles[0] === "ROLE_ADMIN" &&
                  enquiryDetail?.replied === false && (
                    <Button
                      variant="outlined"
                      className="add-cart_btn "
                      onClick={sendReply}
                    >
                      {" "}
                      {/* Send Reply */}
                      {data.buttonMessage}
                    </Button>
                  )}
              </Stack>{" "}
              <span style={{ color: "red" }}>{errMessage}</span>
              {dialogBox && (
                <DialogBox
                  message="Reply Sent Successfully"
                  closeMessage="OK"
                  closeModal={closeModal}
                />
              )}
            </Box>
          </Container>
        </div>
      )}
    </>
  );
};

export default ProductEnquiryReplies;
