import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
  } from "@mui/material";
  import { useState } from "react";
  
  
  
  
  const DialogBox = (props) => {
    const { message, closeMessage, closeModal } = props;
    const [open, setOpen] = useState(true);
  
    const handleClose = () => {
      setOpen(false);
      closeModal  && closeModal();
    };
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{closeMessage}</Button>
        </DialogActions>
      </Dialog>
    );
  };
  
  export default DialogBox;
  