import Carousel from "react-material-ui-carousel";
import { Paper } from "@material-ui/core";
import React from "react";
import { Button } from "@mui/material";
import { baseUrlAietplBackend, baseUrlAietplImage, baseUrlAietplThumbnailImage } from "../../utils/ApiUtils";
import ReactHtmlParser from "html-react-parser";
import { NavLink } from "react-router-dom";

const ImageCarousel = ({ items, desc, productId }) => {
  return (
    <>
      <Carousel

      >
        {Array.isArray(items) && items.map((item, index) => (
          <div key={index}>
            {item && (
              <Paper className="p-4">
                <img
                  src={baseUrlAietplImage + "/" + item}
                  // src={item}
                  // alt={item.name}
                  alt="spices"
                  style={{
                    maxWidth: "100%",
                    width: "50vw",
                    height: "35vh",
                  }}
                />
                 <br />
                {desc && ReactHtmlParser(desc.substring(0, 600))}
                <br />
                <NavLink to={"/product-details/" + productId}>
                  <Button variant="contained" color="primary">
                    View More
                  </Button>
                </NavLink>
              </Paper>
            )}{" "}
          </div>
        ))}
      </Carousel>
    </>
  );
};

export default ImageCarousel;
