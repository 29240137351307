import React, { useEffect, useState } from "react";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import TopNav from "../Home/TopNav";
import Footer from "../Home/Footer";
import ApiHelper from "../../utils/ApiHelper";
import { useDispatch, useSelector } from "react-redux";
import { baseUrlAietplBackend, baseUrlAietplImage, baseUrlAietplThumbnailImage } from "../../utils/ApiUtils";
import ResponsiveDialogBox from "../Common/ResponsiveDialogBox";
import {
  cartcount,
  cartdetail,
  tempCartData,
  updateQunatityTempCartData,
} from "../Redux/action-creators";
import DeleteBox from "../Common/DeleteBox";
import DialogBox from "../Common/DialogBox";

let cartIdCounter = 0;
const ProductWishlist = () => {
  const [wishlistedProduct, setWishlistedProduct] = useState([]);
  const logindata = useSelector((state) => state.loginreducer);
  const cartdata = useSelector((state) => state.cartreducer);
  const [isWishlisting, setIsWishlisting] = useState(false);
  const dispatch = useDispatch();
  const [dialogBox, setDialogBox] = useState({
    status: false,
    message: "",
  });
  const [deleteBox, setDeleteBox] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [deleteDialogBox, setDeleteDialogBox] = useState(false);
  console.log(cartdata.data, "cartdata");
  useEffect(() => {
    getWishlistedProduct();
  }, []);
  const getWishlistedProduct = () => {
    setIsWishlisting(true);
    ApiHelper.getWishlistedProductByUserId(logindata?.data?.id,logindata?.data?.accessToken)
      .then((res) => {
        setWishlistedProduct(res);
        setIsWishlisting(false);
      })
      .catch((err) => {
        console.log(err);
        setIsWishlisting(false);
      });
  };
  const removeFromWishlistClick = async () => {
    ApiHelper.removeFromWishlist(deleteId,logindata?.data?.accessToken).then((res) => {
      getWishlistedProduct();
    });
  };
  const closeModal = () => {
    setDialogBox({ ...dialogBox, status: false, message: "" });
    setDeleteDialogBox(false);
  };
  const addToCart = async (item) => {
    console.log(item);
    const productData = item.productMaster;
    if (logindata?.data.length !== 0) {
      let current = new Date();
      let date = `${current.getFullYear()}-0${current.getMonth() + 1
        }-${current.getDate()}`;
      const record = {
        quantity: 1,
        userId: logindata.data.id,
        productMaster: {
          productId: productData.productId,
        },
        crDate: date,
        detailedRequirement: "",
      };
      const addToEnquiry = await ApiHelper.addToEnquiry(record,logindata.data.accessToken);
      if (addToEnquiry === "add product") {
        // dispatch(cartcount(cartdata.cartCount + 1));
        const cartCount = await ApiHelper.getCartByUserId(logindata.data.id,logindata.data.accessToken);
        dispatch(cartdetail(cartCount));
        dispatch(cartcount(cartCount.length));
      }
    } else {
      const index = cartdata.tempdata?.findIndex(
        (prod) => prod.productId === productData.productId
      );
      const findObjectById = cartdata.tempdata?.find(
        (obj) => obj.productId === productData.productId
      );
      if (findObjectById) {
        dispatch(
          updateQunatityTempCartData(index, Number(findObjectById.quantity) + 1)
        );
      } else {
        const record = {
          cart_id: ++cartIdCounter,
          productName: productData.productName,
          productPrice: productData.productPrice,
          quantity: 1,
          productDescription: productData.productDescription,
          fileName: productData.imageFilenames[0],
          thumbnailFileName: productData.thumbnailFilenames[0],
          productId: productData.productId,
          userId: logindata.data.id,
          stocks: item.stocks,
        };
        dispatch(tempCartData(record));
      }
    }
  };
  const onDeleteClick = async (id) => {
    setDeleteBox(true);
    setDeleteId(id);
  };
  return (
    <>
      <TopNav />
      <Container className="py-5">
        <div>
          <h2 className="form-heading">My Wishlist</h2>
        </div>
        <Row className="pt-5">
          <Col>
            {isWishlisting ? (
              <span style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "50vh",
              }}>
                <Spinner />
              </span>
            ) : wishlistedProduct.length !== 0 ? (
              wishlistedProduct &&
              wishlistedProduct.map((wish, index) => (
                <Row className="wishlist-wrap d-flex justify-content-between">
                  <Col sm={6}>
                    <div className="row d-flex justify-content-start">
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <img
                          src={
                            baseUrlAietplThumbnailImage +
                            "/" +
                            wish.productMaster.thumbnailFilenames[0]
                          }
                          className="img-fluid wishlist-image"


                          alt="wishlisted product"

                        />
                      </div>
                      <div className="col-lg-8 col-md-6  col-sm-12 product-details mr-2">
                        <p className="prod-name mb-0">
                          {wish.productMaster.productName}
                        </p>
                        <p className="mb-0">Export By AIE TRADE</p>
                        <p>&#8377; {wish.productMaster.productPrice}</p>
                      </div>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="row d-flex ">
                      <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                        <button
                          className="add-cartbtn"
                          onClick={() => addToCart(wish)}
                        >
                          {cartdata.data.map((cart, innerIndex) => (
                            <div key={innerIndex}>
                              {wish.productMaster.productId ===
                                cart.productMaster.productId && "Item In Cart"}
                            </div>
                          ))}

                          {!cartdata.data.find(
                            (cart, innerIndex1) =>
                              cart.productMaster.productId ===
                              wish.productMaster.productId
                          ) && <>Add to Cart</>}
                        </button>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                        <button
                          className="remove-cartbtn"
                          onClick={() => {
                            onDeleteClick(wish.wishlistId);
                          }}
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  </Col>
                  <hr />
                </Row>
              ))
            ) : (
              <span> Your wishlist is empty..!</span>
            )}
          </Col>
        </Row>
      </Container>
      <Footer />
      {dialogBox.status && (
        // <DialogBox
        //   message={dialogBox.message}
        //   closeMessage="OK"
        //   closeModal={closeModal}
        // />
        <ResponsiveDialogBox closeModal={closeModal} />
      )}

      {dialogBox.status && (
        <DialogBox
          message="Product added to Enquiry"
          closeMessage="OK"
          closeModal={closeModal}
        />
      )}
      {deleteBox && (
        <DeleteBox
          closeMessage="CANCEL"
          closeModal={closeModal}
          deleteRecord={removeFromWishlistClick}
          successMessage="Remove"
          message="Are you sure you want to remove?"
        />
      )}
      {deleteDialogBox && (
        <DialogBox
          message="Product removed from wishlist"
          closeMessage="OK"
          closeModal={closeModal}
        />
      )}
    </>
  );
};

export default ProductWishlist;
