import { configureStore } from '@reduxjs/toolkit';
import reducers from './reducers/index';
import {persistReducer,persistStore} from "redux-persist";
import storage from 'redux-persist/lib/storage';
// import { FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';

const persistConfig={
	key:'persist-store',
	whitelist :['loginreducer','cartreducer'],
	storage
}
const persistedReducer = persistReducer(persistConfig, reducers)

const store = configureStore({
	reducer: persistedReducer,
	
	// middleware: (getDefaultMiddleware) =>
	// 	getDefaultMiddleware({
	// 		serializableCheck: {
	// 			ignoredActions: [ FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER ]
	// 		}
	// 	})
});
export const persistor=persistStore(store)

export default store
