const initialState = {
 
   data:[]
   
}

const loginreducer = (state = initialState, action) => {
    switch (action.type) {


        case "LOGINDETAIL": return {
            ...state,
           data:action.data
        }

      



        default: return state;
    }
}



export default loginreducer;