import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import LocalMallIcon from '@mui/icons-material/LocalMall';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import { Link, useLocation } from "react-router-dom";

export default function ResponsiveDialogBox(props) {
  const [open, setOpen] = React.useState(true);
  const { closeModal } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();
  const pathName = location.pathname;
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    closeModal && closeModal();
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Open responsive dialog
      </Button>
      <Dialog
        
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title" 
        fullWidth={true}
      >
        <DialogContent className="productadded-msg text-center">
          <DialogContentText> <h4 className="text-success">Product Added To Enquiry Cart</h4></DialogContentText>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center">
          
        { pathName === "/products"?
           <button autoFocus onClick={handleClose} className="outlined-btn mx-2"  >
        <AddToPhotosIcon/>   Add More Products
         </button>:
          <Link to="/products" className="addproducts" >
            {" "}
            <button autoFocus onClick={handleClose} className="outlined-btn " >
            <AddToPhotosIcon/>   Add More Products
            </button>
          </Link>}
       
          
          
          <Link to="/product-list">
            <button onClick={handleClose} autoFocus variant="text" className="outlined-btn ">
            <LocalMallIcon/>  Go To Cart
            </button>
          </Link>
          
        </DialogActions>
      </Dialog>
    </div>
  );
}
