import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Content from '../src/components/Routes/Content'
import { Router } from 'react-router-dom';
import { BrowserRouter } from "react-router-dom";
import Landing from './components/Home/Landing';
function App() {
  return (
    
    <div className="App">
      <BrowserRouter>
      <Content/>
      </BrowserRouter>
      {/* <Landing/> */}
    </div>
   
  );
}

export default App;
