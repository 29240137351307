const initialState = {
  data: [],
  cartCount: 0,
  index: 0,
  quantity: 1,
  tempdata: [],
};

const cartreducer = (state = initialState, action) => {
  switch (action.type) {
    case "CARTDETAIL":
      return {
        ...state,
        data: action.data,
      };
    case "CARTCOUNT":
      return {
        ...state,
        cartCount: action.cartCount,
      };
    case "UPDATECARTQUANTITY":
      const updatedCartItems = state.data.map((item, i) => {
        if (i === action.index) {
          return {
            ...item,
            quantity: action.quantity,
          };
        }
        return item;
      });

      return {
        ...state,
        data: updatedCartItems,
      };

      case "UPDATECARTREQUIREMENT":
        const updatedCartRequirements = state.data.map((item, i) => {
          if (i === action.index) {
            return {
              ...item,
              detailedRequirement: action.detailedRequirement,
            };
          }
          return item;
        });
  
        return {
          ...state,
          data: updatedCartRequirements,
        };

    case "UPDATETEMPCARTQUANTITY":
      const updatedTempCartItems = state.tempdata.map((item, i) => {
        if (i === action.index) {
          return {
            ...item,
            quantity: action.quantity,
          };
        }
        return item;
      });

      return {
        ...state,
        tempdata: updatedTempCartItems,
      };

    case "TEMPCARTDETAIL":
      const newItems = action.tempdata;

      if (Array.isArray(newItems)) {
        return {
          ...state,
          tempdata: [...state.tempdata, ...newItems],
        };
      } else {
        return {
          ...state,
          tempdata: [...state.tempdata, newItems],
        };
      }

    case "RESETCART":
      return {
        ...state,
        data: [],
        tempdata: [],
      };

      case "REMOVECARTITEM":
       
      
      return {
        ...state,
        data: state.data.filter(
          (item, index) => item.cart_id !== action.cartIds
        )
      };

    case "REMOVETEMPCARTITEM":
      const tempcartIdsToDelete = action.index;
      
      return {
        ...state,
        tempdata: state.tempdata.filter(
          (_, index) => index !== tempcartIdsToDelete
        ),
      };

    case "UPDATECOUNTBYUSER":
      return {
        ...state,
        cartCount: Number(state.cartCount) - Number(action.count),
      };

    default:
      return state;
  }
};

export default cartreducer;
