import React from 'react'
import SideNav from '../Sidebar/SideNav'
import { Container, Row, Col, Card } from 'react-bootstrap'
import Img1 from '../../assets/images/global-export.png'
import Img2 from '../../assets/images/import.png'
import Img3 from '../../assets/images/call.png'
import Img4 from '../../assets/images/customers.png'



const Dashboard = () => {
  return (
    <>
    <SideNav/>
     <Container className='pt-4'>
        <div>
            <h1 className='dash-heading-h1'>Dashboard</h1>
        </div>
     <Row className='pt-3'>
        <Col sm={3}>
        <div className='dash-tile-1'>
            <div className='d-flex justify-content-between'>
                <img src={Img1} className='img-fluid' />
                <div>
                <p className='dash-p'>Total Exports</p>
                {/* <p className='mb-0 dash-num'>36</p> */}
                </div>
            </div>
        </div>
        </Col>
        <Col sm={3}>
        <div className='dash-tile-2'>
        <div className='d-flex justify-content-between'>
                <img src={Img2} className='img-fluid' />
                <div>
                <p className='dash-p'>Total Imports</p>
                {/* <p className='mb-0 dash-num'>36</p> */}
                </div>
            </div> 
        </div>
        </Col>
        <Col sm={3}>
        <div className='dash-tile-3'>
        <div className='d-flex justify-content-between'>
                <img src={Img3} className='img-fluid' />
                <div>
                <p className='dash-p'>Total Enquiries</p>
                {/* <p className='mb-0 dash-num'>36</p> */}
                </div>
            </div> 
        </div>
        </Col>
        <Col sm={3}>
        <div className='dash-tile-4'>
        <div className='d-flex justify-content-between'>
                <img src={Img4} className='img-fluid' />
                <div>
                <p className='dash-p'>Total Customers</p>
                {/* <p className='mb-0 dash-num'>36</p> */}
                </div>
            </div> 
        </div>
        </Col>
     </Row>
     </Container>
    </>
  )
}

export default Dashboard