import React, { useState, useEffect } from "react";
import { Form, Spinner } from "react-bootstrap";
import { Container, Table, TableRow } from "@mui/material";
import ApiHelper from "../../utils/ApiHelper";
import { baseUrlAietplBackend, baseUrlAietplImage, baseUrlAietplThumbnailImage } from "../../utils/ApiUtils";
import { TableCell } from "@mui/material";
import { IconButton } from "@mui/material";
import QuickreplyTwoToneIcon from "@mui/icons-material/QuickreplyTwoTone";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Collapse } from "@mui/material";
import { Box } from "@mui/material";
import { TableHead } from "@mui/material";
import { TableBody } from "@mui/material";
import { Grid } from "@mui/material";
import { TableContainer } from "@mui/material";
import { Paper } from "@mui/material";

import { Modal } from "react-bootstrap";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai"; // icons form react-icons
import { IconContext } from "react-icons";
import ReactPaginate from "react-paginate";

import SideNav from "../Sidebar/SideNav";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function Row(props) {
  const { row } = props;
  const [open, setOpen] = useState(false);
  console.log(row);

  const history = useNavigate();
  const NumberSeperator = (input) => {
    return new Intl.NumberFormat("en-IN", { minimumFractionDigits: 2 }).format(
      input
    );
  };

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell width={"1%"}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {/* <TableCell  width={'10%'}>
          {row.name}
        </TableCell> */}
        <TableCell width={"15%"}>{row?.enquiryNo}</TableCell>
        <TableCell width={"24%"}>{row?.crDate}</TableCell>
        <TableCell width={"15%"}>{row?.fullName}</TableCell>
        {/* <TableCell align="right" width={"15%"}>
          {row.emailId}
        </TableCell> */}
        {/* <TableCell align="right" width={"10%"}>
          {row.contactNo}
        </TableCell> */}
        {row?.replied === false ? (
          <TableCell align="right" width={"20%"}>
            <QuickreplyTwoToneIcon
              onClick={() =>
                history("/productenquiryreplies/" + row?.productEnquiryId)
              }
            />
          </TableCell>
        ) : (
          <TableCell align="right" width={"20%"}>
            <VisibilityOutlinedIcon
              onClick={() =>
                history("/productenquiryreplies/" + row?.productEnquiryId)
              }
            />
          </TableCell>
        )}
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: "2px", paddingTop: 0 }}
          colSpan={7}
          className="bg-slate-100"
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table
                size="medium"
                aria-label="purchases"
                id="order-details"
                className="bg-white my-6"
              >
                <TableHead>
                  <TableRow>
                    <TableCell width={"25%"}>Product Image</TableCell>
                    <TableCell width={"25%"}>Product Name</TableCell>
                    <TableCell width={"50%"}>Product Price</TableCell>
                    <TableCell align="right" width={"10%"}>
                      Quantity
                    </TableCell>
                    {/* <TableCell align="right" width={'15%'}>Total price (₹)</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row?.productEnquiryDetails?.map((historyRow, index) => (
                    <TableRow key={index}>
                      {/* <TableCell component="th" scope="row"> */}
                      <TableCell>
                        <img
                          src={
                            baseUrlAietplThumbnailImage +
                            "/" +
                            historyRow.productMaster.thumbnailFilenames[0]
                          }
                          width={50}
                          height={50}
                          alt="Product Name"
                          title=""
                          className="max-w-sm rounded border bg-white p-1"
                        />
                      </TableCell>
                      <TableCell>
                        {historyRow.productMaster.productName}
                      </TableCell>

                      {/* <TableCell>{historyRow.enquiryText}</TableCell> */}

                      <TableCell>
                        <span>&#x20b9;</span>{" "}
                        {NumberSeperator(
                          historyRow.productMaster?.productPrice
                        )}
                        /-
                      </TableCell>
                      <TableCell>{historyRow.productQuantity}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Grid container className="order-info">
                {/* <Grid item md={6} sm={12} className="p-2">
                  <p>Address</p>
                
                  {row.address1}, {row.address2}, {row.address3}, {row.pincode}
                </Grid> */}
                <Grid item md={12} sm={12} className="p-2">
                  <Form.Label>Description:</Form.Label>
                  <p>{row?.description}</p>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const ProductEnquiryList = () => {
  const [enquiryList, setenquiryList] = useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [enquiryId, setenquiryId] = useState(0);
  const [enquiryDetail, setenquiryDetail] = useState({});
  const logindata = useSelector((state) => state.loginreducer);
  const history = useNavigate();
  const productPerPage = 10;
  //   const router = useRouter();

  const getData = async (pageNo) => {
    setIsLoading(true);
    const data = await ApiHelper.getproductEnquiry(
      pageNo,
      productPerPage,
      logindata.data.accessToken
    );
    // const sortedItems = [...data].sort((a, b) => b.productEnquiryId - a.productEnquiryId);
    // console.log(data);

    setenquiryList(data);
    setIsLoading(false);
  };
  useEffect(() => {
    if (
      logindata.data.length !== 0 &&
      logindata.data?.roles[0] === "ROLE_ADMIN"
    ) {
      window.scroll(0, 0);
      getData(1);
    } else {
      history("/login");
    }
  }, []);

  const updateParentState = (childData, enquiryId, row) => {
    setOpenModal(childData);
    setenquiryId(enquiryId);
    setenquiryDetail(row);
  };
  return (
    <>
      {/* <VendorSidebar /> */}
      <SideNav />
      {isLoading ? (
        <span
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "50vh",
          }}
        >
          <Spinner />
        </span>
      ) : (
        <div className="content-wrapper">
          <Container className="pt-4">
            <Box component="main">
              <Container className="vendor-box">
                <div className="two pb-3">
                  <h2 className="form-heading">
                    Product's Enquiry List
                    {/* <span>Enquiries by customers</span> */}
                  </h2>
                </div>

                <TableContainer component={Paper}>
                  <Table aria-label="collapsible table">
                    <TableHead className="mui-tableHead">
                      <TableRow>
                        <TableCell />
                        <TableCell>Enquiry No</TableCell>
                        <TableCell>Enquiry Date</TableCell>
                        <TableCell>Full Name</TableCell>
                        {/* <TableCell>Email Id</TableCell>
                      <TableCell align="right">Contact No.</TableCell> */}

                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {openModal === false && enquiryList.length !== 0
                        ? enquiryList.ProductEnquiry.map((row) => (
                            <Row
                              key={row.productEnquiryId}
                              row={row}
                              updateParentState={updateParentState}
                            />
                          ))
                        : "no data found"}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Container>
            </Box>
          </Container>
        </div>
      )}
      <div className="content-wrapper">
        <Container className="pt-4">
          <Box component="main">
            {enquiryList.ProductEnquiry && (
              <ReactPaginate
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                activeClassName={"active"}
                onPageChange={(event) => {
                  // onPageHandleChange(event.selected)

                  getData(event.selected + 1);
                }}
                pageCount={Math.ceil(
                  enquiryList.totalProductEnquiry / productPerPage
                )}
                breakLabel="..."
                previousLabel={
                  <IconContext.Provider
                    value={{ color: "#B8C1CC", size: "36px" }}
                  >
                    <AiFillLeftCircle />
                  </IconContext.Provider>
                }
                nextLabel={
                  <IconContext.Provider
                    value={{ color: "#B8C1CC", size: "36px" }}
                  >
                    <AiFillRightCircle />
                  </IconContext.Provider>
                }
              />
            )}
          </Box>
        </Container>
      </div>
    </>
  );
};

export default ProductEnquiryList;
