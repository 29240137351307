import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import about from '../../assets/images/abut-img.png'
import teams from '../../assets/images/teams.png'
import history from '../../assets/images/history.png'
import clients from '../../assets/images/clients.png'
import {FaDotCircle} from 'react-icons/fa'
import TopNav from "./TopNav";
import Footer from "./Footer";
const About = () => {
    return(
        <>
        <TopNav/>
        <section>
            <div className="about-us-banner">
                <Container>
                    <Row className="px-5">
                        <Col className="about-us-grid p-3">
                        <div className="border-orange p-3">
                        <div className="py-3">
              <h2 className="text-center sub-heading-white">ABOUT AIETPL</h2>
            </div>
                        </div>
                        </Col>
                    </Row>

                </Container>

            </div>
        </section>
        <section>
            <Container>
                <Row className="pb-3">
                    <Col sm={6}>
                    <div className="py-5">
                        <img className="img-fluid abt-img" src={about} alt="About-us" />
                    </div>
                    </Col>
                    <Col sm={6}>
                        <div className="pt-5">
                        <p className="p-info">
                    AIETPL It was founded in the Year 1996 by the name ANT
                    International Exports (AIE), as a sole proprietorship
                    Entrepreneurship. Later in the Year 2016 It was reformed to
                    AIE Trade Private Limited as a Company to cater the various
                    business profiles.
                  </p>
                </div>
                <div>
                  <h4 className="h4-heading">Mission and Values</h4>
                  <div className="d-flex justify-content-start">
                  <span><FaDotCircle className="mx-2 icon-dot-orange"/></span>
                  <p className="p-info">
                    AIETPL prides itself for the win of trust of our clients
                    with our quality, service and time management.
                  </p>
                  </div>
                 
                  <div className="d-flex justify-content-start">
                  <span><FaDotCircle className="mx-2 icon-dot-orange"/></span>
                  <p className="p-info">
                    Passion for business runs in our veins.
                  </p>
                  </div>
                  <div className="d-flex justify-content-start">
                  <span><FaDotCircle className="mx-2 icon-dot-orange"/></span>
                  <p className="p-info">
                    We truly Value your association with us and our relationship
                    with you will always have the top priority.
                  </p>
                  </div>
                  <div className="d-flex justify-content-start">
                  <span><FaDotCircle className="mx-2 icon-dot-orange"/></span>
                  <p className="p-info">
                    Business ethics and principals form the core of this
                    Company.
                  </p>
                  </div>
                  <div className="d-flex justify-content-start">
                  <span><FaDotCircle className="mx-2 icon-dot-orange"/></span>
                  <p className="p-info">
                    Welcome to AIETPL to experience India and its services like
                    never before.
                  </p>
                  </div>

                  
                  
                 
                        </div>
                    
                    </Col>

                </Row>
                <Row className="pb-3">
                    
                    <Col sm={6}>
                        <div className="py-3">
                        <div>
                  <h4 className="h4-heading">History and Milestones</h4>
                  <p className="p-info">
                  AIETPL It was founded in the Year 1996 by the name ANT International Exports, as a sole proprietorship Entrepreneurship. Later in the Year 2016 It was upgraded to AIE Trade Private Limited as a Company to cater the various business profiles. 
                  </p>
                  <p className="p-info">
                  We are engaged in varied business activities such as
                  </p>
                  <div className="d-flex justify-content-start">
                    <span><FaDotCircle className="mx-2 icon-dot-orange"/></span>
                    <p className="p-info">
                    Manufacturing of Agro and similar commodities, various Electronic Goods. 
                  </p>
                  </div>
                  <div className="d-flex justify-content-start">
                    <span><FaDotCircle className="mx-2 icon-dot-orange"/></span>
                    <p className="p-info">
                    Exports of Agriculture, Agro Processing, Spices, Food Commodity, Specialty Chemicals, Chemical Intermediates, Cinema Equipment’s and supplies, Electronic Goods etc.,
                  </p>
                  </div>
                  <div className="d-flex justify-content-start">
                    <span><FaDotCircle className="mx-2 icon-dot-orange"/></span>
                    <p className="p-info">
                    Imports of Agro Commodities, Hardware, Sanitary ware, and electronic Components. 
                  </p>
                  </div>
                        </div>
                        </div>
                    
                    </Col>
                    <Col sm={6}>
                    <div className="py-5">
                        <img className="img-fluid abt-img" src={history} alt="About-us" />
                    </div>
                    </Col>

                </Row>
                <Row className="pb-3">
                    <Col sm={6}>
                    <div className="py-2">
                        <img className="img-fluid abt-img" src={teams} alt="About-us" />
                    </div>
                    </Col>
                    <Col sm={6} className="py-3">
                        <div className="py-5">
                        <div>
                  <h4 className="h4-heading">Team and Expertise</h4>
                  
                  <div className="d-flex justify-content-start">
                    <span><FaDotCircle className="mx-2 icon-dot-orange"/></span>
                    <p className="p-info">
                    Team AIE has expertise in various departments, viz; procurement, Quality assessment, Logistics handling, etc.,                  </p>
                  </div>
                  <div className="d-flex justify-content-start">
                    <span><FaDotCircle className="mx-2 icon-dot-orange"/></span>
                    <p className="p-info">
                    Our Team provides value added services and goes beyond horizons to ensure seamless client oriented satisfaction. 
                  </p>
                  </div>
                  
                        </div>
                        </div>
                    
                    </Col>

                </Row>
                <Row className="pb-3">
                    
                    <Col sm={6} className="pt-3">
                        <div className="py-2">
                        <div>
                  <h4 className="h4-heading">Our Clientele & Customers</h4>
                  
                  <div className="d-flex justify-content-start">
                    <span><FaDotCircle className="mx-2 icon-dot-orange"/></span>
                    <p className="p-info">
                    Worldwide customers looking for exclusivity products from India are our prime Clientele.
                    </p>
                  </div>
                  <div className="d-flex justify-content-start">
                    <span><FaDotCircle className="mx-2 icon-dot-orange"/></span>
                    <p className="p-info">
                    Our Client gets the exceptional product delivered right up to his doors, while relying entirely on our unmatched service. 
                  </p>
                  </div>
                  <div className="d-flex justify-content-start">
                    <span><FaDotCircle className="mx-2 icon-dot-orange"/></span>
                    <p className="p-info">
                    Our Clientele describe us as their Olive branch in India.
                  </p>
                  </div>
                  <div className="d-flex justify-content-start">
                    <span><FaDotCircle className="mx-2 icon-dot-orange"/></span>
                    <p className="p-info">
                    Describe your target audience or customer base. Explain who benefits from your products or services and how you address their needs. You can also include testimonials or success stories from satisfied clients or customers.
                  </p>
                  </div>
                  
                        </div>
                        </div>
                    
                    </Col>
                    <Col sm={6}>
                    <div className="py-3">
                        <img className="img-fluid abt-img" src={clients} alt="About-us" />
                    </div>
                    </Col>

                </Row>
            </Container>
        </section>
        <Footer/>
        </>
    )
}

export default About;