import { Container, Row, Col } from "react-bootstrap";
import Success from "../../assets/images/send-message.png";
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import TopNav from "../Home/TopNav";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';

import { Link } from "react-router-dom";

const Enquirysuccess = () => {
   
    
  return (
    <>
    <TopNav/>
     
      <Container className="py-5">
        <Row className="d-flex justify-content-center py-5">
          <Col sm={4} >
            <div className="text-center">
            <img
            src={Success}
            className="img-fluid"
            alt="Empty Cart"
            height={170}
            width={170}
          />
            </div>
          </Col>
          <Col sm={5}>
          <div className="py-3">
          <h4 className="title_login">Your enquiry has been submitted successfully!</h4>
          <p className="pb-7 title">Thank you for getting in touch. We will get back to you shortly.</p>{" "}
          
        
            <div>
              <Link to="/Products">
            <button className="outlined-btn"><ManageSearchIcon/> Explore More Products</button></Link>
            <Link to="/productenquiries"><button className="outlined-btn"><ForwardToInboxIcon/> Go To Enquiries</button></Link>

            </div>
          </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Enquirysuccess;
