import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

class AxiosFetchUtils {
   static axiosInstance;
   static getInstance = () => {
    if (AxiosFetchUtils.axiosInstance === undefined) {
      AxiosFetchUtils.axiosInstance = axios.create();
    }
    return AxiosFetchUtils.axiosInstance;
  };
}

export const successHandler = (response) => response.data;

export const failureHandler = (axiosResponse) => {
  throw axiosResponse;
};

export default AxiosFetchUtils;
