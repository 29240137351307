import React from "react";
import { Routes, Route } from "react-router-dom";
import Landing from "../Home/Landing";
import Login from "../Login/Login";
import About from "../Home/About";
import CallToAction from "../Home/CallToAction";
import Products from "../Product/Products";
import Registration from "../Register/Registration";
import ProductDetails from "../Product/ProductDetails";
import ProductsList from "../Product/ProductsList";
import Enquiry from "../Home/Enquiry";
// import TopBar from '../Common/TopBar';
import ProductCategory from "../Admin/ProductCategory";
import ProductMaster from "../Admin/ProductMaster";
import UnitMaster from "../Admin/UnitMaster";
import Dashboard from "../Admin/Dashboard";
import ProductMasterList from "../Admin/ProductMasterList";
import Enquirysuccess from "../Product/Enquirysuccess";
import EnquiryList from "../Admin/EnquiryList";
import CallToActionList from "../Admin/CallToActionList";
import ProductEnquiryList from "../Admin/ProductEnquiryList";
import ProductEnquiries from "../Product/ProductEnquiries";
import ProductEnquiryReplies from "../Admin/ProductEnquiryReplies";
import ProductWishlist from "../Product/ProductWishlist";

const Content = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/login" element={<Login />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/call-to-action" element={<CallToAction />} />
        <Route path="/products" element={<Products />} />
        <Route path="/register" element={<Registration />} />
        <Route path="/product-details" element={<ProductDetails />} />
        <Route path="/product-details/:id" element={<ProductDetails />} />
        <Route path="/product-list" element={<ProductsList />} />
        <Route path="/enquiry" element={<Enquiry />} />
        {/* <Route path='/admin' element={<TopBar/>} /> */}
        <Route path="/product-category" element={<ProductCategory />} />
        <Route path="/product-master" element={<ProductMaster />} />
        <Route path="/product-master/:id" element={<ProductMaster />} />
        <Route path="/product-master-list" element={<ProductMasterList />} />
        <Route path="/unit-master" element={<UnitMaster />} />
        <Route path="/Enquirysuccess" element={<Enquirysuccess />} />

        <Route path="/enquiry-list" element={<EnquiryList />} />
        <Route path="/call-to-action-list" element={<CallToActionList />} />
        <Route path="/product-enquiry-list" element={<ProductEnquiryList />} />
        <Route path="/productenquiries" element={<ProductEnquiries />} />
        <Route
          path="/productEnquiryReplies/:id"
          element={<ProductEnquiryReplies />}
        />
        <Route path="/product-wishlist" element={<ProductWishlist />} />
      </Routes>
    </>
  );
};

export default Content;
