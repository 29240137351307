import * as React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../../assets/images/aietpl-logo.webp";
import { AiOutlineShoppingCart } from "react-icons/ai";
import Badge from "@mui/material/Badge";
import { useDispatch, useSelector } from "react-redux";
import { Fade, Menu, MenuItem } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import ApiHelper from "../../utils/ApiHelper";
import { cartcount, cartdetail } from "../Redux/action-creators";

const TopNav = () => {
  const dispatch = useDispatch();
  const logindata = useSelector((state) => state.loginreducer);
  const cartdata = useSelector((state) => state.cartreducer);
  console.log(cartdata.data);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const history = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (logindata.data.length !== 0) {
      getCartData();
    }
  }, []);
  const getCartData = async () => {
    const cartCount = await ApiHelper.getCartByUserId(logindata.data.id,logindata.data.accessToken);
    dispatch(cartdetail(cartCount));
    dispatch(cartcount(cartCount.length));
  };
  return (
    <>
      <Navbar bg="dark" data-bs-theme="dark" sticky="top" className="py-3">
        <Container fluid className="px-5">
          {/* <Navbar.Brand href="#home">Navbar with text</Navbar.Brand> */}
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            {logindata.data.length !== 0 ? (
              <>
                <Nav.Link
                  // href="/login"
                  className="signin-link"
                  onMouseEnter={handleClick}
                >
                  {" "}
                  {logindata.data.firstName}
                  <Menu
                    id="fade-menu"
                    MenuListProps={{
                      "aria-labelledby": "fade-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                    onMouseLeave={handleClose}
                  >
                    <MenuItem
                      onClick={() => {
                        history("/productenquiries");
                      }}
                    >
                      My Enquiries
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        history("/product-wishlist");
                      }}
                    >
                      My Wishlist
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        dispatch(cartdetail([]));
                        dispatch(cartcount(0));
                        history("/login");
                      }}
                    >
                      Logout
                    </MenuItem>
                  </Menu>
                </Nav.Link>
              </>
            ) : (
              <Nav.Link href="/login" className="signin-link">
                SIGN IN/REGISTER
              </Nav.Link>
            )}
            {logindata && logindata.data.length !== 0 ? (
              
                <Link to="/product-list" className="mx-3">
                <Badge
                  badgeContent={cartdata?.cartCount ? cartdata?.cartCount : 0}
                  color="primary"
                >
                  <AiOutlineShoppingCart
                    className="login-icons"
                    color="action"
                  />
                </Badge>
                </Link>
             
            ) : (
              cartdata &&
              cartdata.tempdata.length !== 0 && (
                <Nav.Link className="mx-3" href="/product-list">
                  <Badge
                    badgeContent={cartdata?.tempdata.length}
                    color="primary"
                  >
                    <AiOutlineShoppingCart
                      className="login-icons"
                      color="action"
                    />
                  </Badge>
                </Nav.Link>
              )
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Navbar
        collapseOnSelect
        expand="lg"
        className="bg-body-tertiary bg-white"
        sticky="top"
      >
        <Container fluid className="px-5">
          <Navbar.Brand href="/">
            <img src={logo} alt="logo" width="180px" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/">HOME</Nav.Link>
              <Nav.Link href="/about-us">ABOUT US</Nav.Link>
              {/* <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
            <NavDropdown.Item href="#action/3.2">
              Another action
            </NavDropdown.Item>
            <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="#action/3.4">
              Separated link
            </NavDropdown.Item>
          </NavDropdown> */}
              <Nav.Link href="#">CERTIFICATIONS</Nav.Link>
              <Nav.Link href="/products">PRODUCTS</Nav.Link>
            </Nav>
            <Nav>
              {/* <Nav.Link href="#deets">CONTACT US</Nav.Link> */}
              <Nav.Link eventKey={2} href="/enquiry">
                CONTACT US
              </Nav.Link>
              <Nav.Link href="/call-to-action">ENQUIRY</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default TopNav;
