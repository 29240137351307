import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col, Card, Form, Spinner } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import TopNav from "../Home/TopNav";
import Footer from "../Home/Footer";
import CartImg from "../../assets/images/Producthunt.gif";
import DeleteIcon from "@mui/icons-material/Delete";

import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import Table from "react-bootstrap/Table";
import { AiOutlineMinus, AiOutlinePlusCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { baseUrlAietplBackend, baseUrlAietplImage, baseUrlAietplThumbnailImage } from "../../utils/ApiUtils";
import {
  removeCartData,
  removeTempCartData,
  updateCountByUser,
  updateQunatityTempCartData,
  updatecartquantity,
  updatecartrequirement,
  resetCartItem,
} from "../Redux/action-creators";
import ApiHelper from "../../utils/ApiHelper";
import DialogBox from "../Common/DialogBox";
import { Link, useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import DeleteBox from "../Common/DeleteBox";
const ProductsList = () => {
  const cartdata = useSelector((state) => state.cartreducer);
  const logindata = useSelector((state) => state.loginreducer);
  const [cart, setcart] = useState([]);
  const [quantity, setQuantity] = useState([]);
  const [emptyCart, setemptyCart] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [error, setError] = useState({});
  const dispatch = useDispatch();
  const [deleteId, setDeleteId] = useState(0);
  const [deleteBox, setDeleteBox] = useState(false);
  const [deleteAllBox, setDeleteAllBox] = useState(false);
  
  const history = useNavigate();
  const [dialogBox, setDialogBox] = useState({
    status: false,
    message: "",
  });
  const [enquiryData, setenquiryData] = useState({
    name: "",
    email: "",
    contactNo: "",
    address1: "",
    address2: "",
    address3: "",
    pincode: "",
    description: "",
  });
  useEffect(() => {
    if (logindata.data.length !== 0) {
      const initialQuantity = cartdata.data.map((items) => items.quantity);
      setQuantity(initialQuantity);

      let firstName = logindata.data.firstName;
      let lastName = logindata.data.lastName;
      let fullName = firstName.concat(" ", lastName);
      setenquiryData({
        ...enquiryData,
        name: fullName,
        email: logindata.data.email,
        contactNo: logindata.data.contactNo,
      });
    } else {
      const initialQuantity = cartdata.tempdata.map((items) => items.quantity);
      setQuantity(initialQuantity);
    }
    setIsDataLoading(true);
    setTimeout(() => {
      setemptyCart(true);
      setIsDataLoading(false);
    }, 1000);
  }, []);
  const handleQuantityChange = (event, index, product) => {
    const updatedQuantity = [...quantity];
    let value = parseInt(event.target.value, 10);
    if (isNaN(value) || value <= 0) {
      if (!value || value <= 0) {
        updatedQuantity[index] = 1;
      } else {
        updatedQuantity[index] = "";
      }
    } else {
      updatedQuantity[index] = value;
    }

    setQuantity(updatedQuantity);
    if (event.target.value !== "" || event.target.value !== 0) {
      if (cartdata?.data && cartdata?.data.length !== 0) {
        updateQuantity(cartdata?.data[index].cart_id, value);

        dispatch(updatecartquantity(index, value));
      } else {
        dispatch(updateQunatityTempCartData(index, value));
      }
    }
  };
  const updateQuantity = async (cartId, quantity) => {
    let qty;
    if (!quantity) {
      qty = 1;
    } else {
      qty = quantity;
    }
    const formData = new FormData();
    formData.append("quantity", qty);
    const update = await ApiHelper.updateEnquiryQuantity(cartId, formData,logindata.data.accessToken);
  };
  const incrementQuantity = (index, product) => {
    let count;
    let tempCount;

    let updatedQuantity = [...cartdata?.data];
    let updatedTempQuantity = [...cartdata?.tempdata];
    if (logindata?.data.length !== 0) {
      if (
        updatedQuantity[index].quantity === "" ||
        Number.isNaN(updatedQuantity[index].quantity)
      ) {
        count = 2;
      } else {
        count = Number(updatedQuantity[index].quantity) + 1;
      }

      setQuantity(updatedQuantity);
      updateQuantity(cartdata?.data[index].cart_id, count);
      dispatch(updatecartquantity(index, count));
    } else {
      if (
        updatedTempQuantity[index].quantity === "" ||
        Number.isNaN(updatedTempQuantity[index].quantity)
      ) {
        tempCount = 2;
      } else {
        tempCount = Number(updatedTempQuantity[index].quantity) + 1;
      }
      setQuantity(updatedTempQuantity);
      dispatch(updateQunatityTempCartData(index, tempCount));
    }
  };
  const decrementQuantity = (index, product) => {
    let count;
    let tempCount;
    const updatedQuantity = [...cartdata.data];
    let updatedTempQuantity = [...cartdata?.tempdata];
    if (logindata.data.length !== 0) {
      if (updatedQuantity[index].quantity > 1) {
        count = Number(updatedQuantity[index].quantity) - 1;

        setQuantity(updatedQuantity);
        updateQuantity(cartdata.data[index].cart_id, count);
        dispatch(updatecartquantity(index, count));
      }
    } else {
      tempCount = Number(updatedTempQuantity[index].quantity) - 1;
      setQuantity(updatedTempQuantity);

      dispatch(updateQunatityTempCartData(index, tempCount));
    }
  };
  const NumberSeperator = (input) => {
    return new Intl.NumberFormat("en-IN", { minimumFractionDigits: 2 }).format(
      input
    );
  };
  const onInputChange = (e) => {
    setenquiryData({ ...enquiryData, [e.target.name]: e.target.value });
  };
  const postEnquiry = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);

      let arr = [];
      let emailarr = [];

      for (let i = 0; i < cartdata.data.length; i++) {
        let productDetail = {
          productQuantity: cartdata.data[i].quantity,
          detailedRequirement: cartdata.data[i].detailedRequirement,
          productMaster: {
            productId: cartdata.data[i].productMaster.productId,
          },
        };
        arr.push(productDetail);
        const emailRecord = {
          productName: cartdata.data[i].productMaster.productName,
          quantity: cartdata.data[i].quantity,
          productEnquiry: cartdata?.data[i]?.detailedRequirement?.replace(
            /<[^>]*>?/gm,
            ""
          ),
          fullName: enquiryData.name,
          description: enquiryData.description,
        };
        emailarr.push(emailRecord);
      }
      let current = new Date();
      let date = `${current.getFullYear()}-0${
        current.getMonth() + 1
      }-${current.getDate()}`;
      const record = {
        fullName: enquiryData.name,
        emailId: enquiryData.email,
        contactNo: enquiryData.contactNo,
        address1: enquiryData.address1,
        address2: enquiryData.address2,
        address3: enquiryData.address3,
        pincode: enquiryData.pincode,
        userId: logindata.data.id,
        description: enquiryData.description,
        crDate: date,
        productEnquiryDetails: arr,
      };

      let emailrecords = {
        productEnquiry: emailarr,
      };
      const postEnquiry = await ApiHelper.postproductEnquiry(record,logindata.data.accessToken);

      setIsLoading(false);

      history("/Enquirysuccess");
      const postEnquiryMail = await ApiHelper.productEnquiryMail(emailarr,logindata.data.accessToken);
      // setDialogBox({
      //   ...dialogBox,
      //   status: true,
      //   message:
      //     // "Thank you for registering with Shiplords. We have sent you an email for verification. Please check your spam folder if you do not see the verification email in your inbox.",
      //     "Enquiry has been send successfully",
      // });
      const cartIds = cartdata.data.map((item) => item.cart_id);
      console.log(cartIds);
      await ApiHelper.deleteMultipleEnquiry({ data: cartIds });
      dispatch(
        removeCartData({
          cartIds: [cartIds],
        })
      );
      dispatch(updateCountByUser({ count: cartIds.length }));
      onreset();
    }
  };
  const onreset = () => {
    setenquiryData({
      ...enquiryData,

      address1: "",
      address2: "",
      address3: "",
      pincode: "",
      description: "",
    });
  };
  const closeModal = () => {
    setDialogBox({ ...dialogBox, status: false, message: "" });
    setDeleteBox(false);
    setDeleteAllBox(false);
  };
  const removeProduct = async () => {
    const pindex = cart.findIndex((obj) => obj.cart_id === deleteId);

    const deleteById = await ApiHelper.deleteCartProductById(deleteId,logindata.data.accessToken);
    dispatch(removeCartData(deleteId));
    dispatch(updateCountByUser(1));

    setDeleteBox(false);
  };
  const validateForm = () => {
    let error = {};
    let formIsValid = true;

    if (enquiryData.name === "") {
      formIsValid = false;

      error["name"] = "Please enter name";
    }
    if (enquiryData.email === "") {
      formIsValid = false;

      error["email"] = "Please enter email Id";
    }
    if (enquiryData.contactNo === "") {
      formIsValid = false;

      error["contactNo"] = "Please enter contactNo";
    }
    setError(error);
    return formIsValid;
  };

  
  const deleteAll = () => {
    if (logindata.data.length === 0) {
      setDeleteBox(false);
      dispatch(resetCartItem());
      dispatch(updateCountByUser(cartdata.tempdata.length));
    }
    if (logindata.data.length !== 0) {
      const deleteAllEnquiry = ApiHelper.deleteAllEnquiryCart(logindata.data.accessToken);
      setDeleteBox(false);
      dispatch(resetCartItem());
      dispatch(updateCountByUser({ count: cartdata.data.length }));

      onreset();
    }
  };
  return isDataLoading ? (
    <>
      <TopNav />
      <span
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "50vh",
        }}
      >
        <Spinner />
      </span>
    </>
  ) : (
    <div>
      <TopNav />
      {cartdata.data.length !== 0 || cartdata.tempdata.length !== 0 ? (
        <Container>
          <div className="pt-4">
            <h2 className="text-center sub-heading">ENQUIRY CART</h2>
          </div>
          <Row className="py-5">
            <Col>
              <Table bordered responsive>
                <thead>
                  <tr className="prdct-tableHead">
                    <th width="10%">Sr.No</th>
                    <th width="60%">Product Details</th>
                    <th width="20%">Quantity</th>
                    <th width="10%">Price (INR)</th>
                    <th width="10%">
                      {" "}
                      <span
                        title="Delete All"
                        onClick={() => setDeleteAllBox(true)}
                      >
                        <DeleteIcon /> Delete All
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {logindata.data.length !== 0
                    ? cartdata.data.map((item, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>
                            <div className="row">
                              <div className="col-sm-2">
                                <img
                                  src={
                                    baseUrlAietplThumbnailImage +
                                    "/" +
                                    item.productMaster.thumbnailFilenames[0]
                                  }
                                  width="100px"
                                  className="img-fluid"
                                  alt="product-img"
                                />
                              </div>
                              <div className="col-sm-10">
                                <div className="py-2">
                                  <p className="p-info mb-0">
                                    {item.productMaster.productName}
                                  </p>
                                  <p className="p-info mb-0">
                                    Exported By AIE TRADE
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <Form.Group className="mb-3 mt-1">
                                <Form.Control
                                  as="textarea"
                                  rows={3}
                                  placeholder="Add your requirements"
                                  value={item.detailedRequirement}
                                  onChange={(e) =>
                                    dispatch(
                                      updatecartrequirement(
                                        index,
                                        e.target.value
                                      )
                                    )
                                  }
                                />
                              </Form.Group>
                            </div>
                          </td>
                          <td>
                            <div className="row d-flex justify-content-center">
                              <div className="col-sm-2 py-3">
                                <AiOutlineMinus
                                  onClick={() => decrementQuantity(index, item)}
                                />
                              </div>
                              <div className="col-sm-5 py-2">
                                <Form.Control
                                  type="number"
                                  value={item.quantity}
                                  min={1}
                                  placeholder="1"
                                  onKeyDown={(evt) =>
                                    ["e", "E", "+", "-", "."].includes(
                                      evt.key
                                    ) && evt.preventDefault()
                                  }
                                  onChange={(event) =>
                                    handleQuantityChange(event, index, item)
                                  }
                                />
                              </div>
                              <div className="col-sm-2 py-3">
                                <AiOutlinePlusCircle
                                  onClick={() => incrementQuantity(index, item)}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="py-4">
                              <p>
                                {item.quantity ? (
                                  <>
                                    {" "}
                                    <span>&#x20b9;</span>{" "}
                                    {NumberSeperator(
                                      Number(
                                        item.quantity *
                                          item.productMaster.productPrice
                                      )
                                    )}
                                    /-
                                  </>
                                ) : (
                                  <>
                                    {" "}
                                    <span>&#x20b9;</span>{" "}
                                    {NumberSeperator(
                                      Number(
                                        1 * item.productMaster.productPrice
                                      )
                                    )}
                                    /-
                                  </>
                                )}
                              </p>
                            </div>
                          </td>
                          <td className="text-right" width={"15%"}>
                            <IconButton
                              onClick={() => {
                                setDeleteId(item.cart_id);
                                setDeleteBox(true);
                              }}
                              title="Delete"
                            >
                              <CloseIcon />
                            </IconButton>
                          </td>
                        </tr>
                      ))
                    : cartdata.tempdata.map((item, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>
                            <div className="row">
                              <div className="col-sm-2">
                                <img
                                  src={
                                    baseUrlAietplThumbnailImage + "/" + item.thumbnailFileName
                                  }
                                  width="100px"
                                  className="img-fluid"
                                  alt="product-img"
                                />
                              </div>
                              <div className="col-sm-10">
                                <div className="py-2">
                                  <p className="p-info mb-0">
                                    {item.productName}
                                  </p>
                                  <p className="p-info mb-0">
                                    Exported By AIE TRADE
                                  </p>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="row d-flex justify-content-center">
                              <div className="col-sm-2 py-3">
                                <AiOutlineMinus
                                  onClick={() => decrementQuantity(index, item)}
                                />
                              </div>
                              <div className="col-sm-5 py-2">
                                <Form.Control
                                  type="number"
                                  value={item.quantity}
                                  min={1}
                                  placeholder="1"
                                  onKeyDown={(evt) =>
                                    ["e", "E", "+", "-", "."].includes(
                                      evt.key
                                    ) && evt.preventDefault()
                                  }
                                  onChange={(event) =>
                                    handleQuantityChange(event, index, item)
                                  }
                                />
                              </div>
                              <div className="col-sm-2 py-3">
                                <AiOutlinePlusCircle
                                  onClick={() => incrementQuantity(index, item)}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="py-4">
                              <p>
                                {item.quantity ? (
                                  <>
                                    {" "}
                                    <span>&#x20b9;</span>{" "}
                                    {NumberSeperator(
                                      Number(item.quantity * item.productPrice)
                                    )}
                                    /-
                                  </>
                                ) : (
                                  <>
                                    {" "}
                                    <span>&#x20b9;</span>{" "}
                                    {NumberSeperator(
                                      Number(1 * item.productPrice)
                                    )}
                                    /-
                                  </>
                                )}
                              </p>
                            </div>
                          </td>
                          <td className="text-right" width={"15%"}>
                            <IconButton
                              onClick={() => {
                                setDeleteId(index);
                                setDeleteBox(true);
                              }}
                              title="Delete"
                            >
                              <CloseIcon />
                            </IconButton>
                          </td>
                        </tr>
                      ))}
                </tbody>
              </Table>
              {logindata.data.length === 0 && (
                <div className="d-flex justify-content-end">
                  <Link to="/login">
                    <button className="outlined-btn">Proceed To Enquiry</button>
                  </Link>
                </div>
              )}
            </Col>
          </Row>

          {logindata.data.length !== 0 && (
            <>
              <div className="pt-2 pb-4">
                <h2 className="text-center sub-heading">ENQUIRY FORM</h2>
              </div>
              <Row className="pb-5">
                <Col>
                  <Card className="Enquiry-form-card p-3">
                    <Card.Body>
                      <Form className="row" onSubmit={postEnquiry}>
                        <Form.Group className="col-sm-12 mb-2">
                          <Form.Label>Name</Form.Label>
                          <Form.Control
                            placeholder="Enter your Name"
                            value={enquiryData.name}
                            name="name"
                            onChange={(e) => {
                              onInputChange(e);
                              setError({ ...error, name: "" });
                            }}
                            readOnly
                          />
                          {error.name && (
                            <span style={{ color: "red" }}>{error.name}</span>
                          )}
                        </Form.Group>
                        <Form.Group className="col-sm-6 mb-2">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Enter your Email"
                            value={enquiryData.email}
                            name="email"
                            onChange={(e) => {
                              onInputChange(e);
                              setError({ ...error, email: "" });
                            }}
                            readOnly
                          />
                          {error.email && (
                            <span style={{ color: "red" }}>{error.email}</span>
                          )}
                        </Form.Group>
                        <Form.Group className="col-sm-6 mb-2">
                          <Form.Label>Phone</Form.Label>
                          <Form.Control
                            placeholder="Enter your Number"
                            value={enquiryData.contactNo}
                            name="contactNo"
                            onChange={(e) => {
                              onInputChange(e);
                              setError({ ...error, contactNo: "" });
                            }}
                            readOnly
                          />
                          {error.contactNo && (
                            <span style={{ color: "red" }}>
                              {error.contactNo}
                            </span>
                          )}
                        </Form.Group>
                        <Form.Group className="col-sm-6 mb-2">
                          <Form.Label>Address 1</Form.Label>
                          <Form.Control
                            placeholder="Building/Block/Apartment"
                            value={enquiryData.address1}
                            name="address1"
                            onChange={onInputChange}
                          />
                        </Form.Group>
                        <Form.Group className="col-sm-6 mb-2">
                          <Form.Label>Address 2</Form.Label>
                          <Form.Control
                            placeholder="Street/Sector/Village"
                            value={enquiryData.address2}
                            name="address2"
                            onChange={onInputChange}
                          />
                        </Form.Group>
                        <Form.Group className="col-sm-6 mb-2">
                          <Form.Label>Landmark</Form.Label>
                          <Form.Control
                            placeholder="Landmark"
                            value={enquiryData.address3}
                            name="address3"
                            onChange={onInputChange}
                          />
                        </Form.Group>
                        <Form.Group className="col-sm-6 mb-2">
                          <Form.Label>Pincode</Form.Label>
                          <Form.Control
                            placeholder="Pincode"
                            value={enquiryData.pincode}
                            name="pincode"
                            onChange={onInputChange}
                          />
                        </Form.Group>
                        <Form.Group className="col-sm-12 mb-2">
                          <Form.Label>Descriptipn</Form.Label>
                          <Form.Control
                            as="textarea"
                            placeholder="Add Descriptipn here"
                            value={enquiryData.description}
                            name="description"
                            onChange={onInputChange}
                          />
                        </Form.Group>
                        <div className="py-3">
                          <button
                            className="seemore-btn"
                            type="submit"
                            disabled={isLoading}
                          >
                            {isLoading && <Spinner />}
                            SUBMIT ENQUIRY
                          </button>
                        </div>
                      </Form>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </Container>
      ) : (
        <>
          {emptyCart && (
            <Container>
              <Row className="d-flex justify-content-center py-5">
                <Col sm={4}>
                  <div>
                    <img
                      src={CartImg}
                      className="img-fluid"
                      alt="Empty Cart"
                      height={300}
                      width={300}
                    />
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="py-5">
                    <h3 className="title_login">
                      Your cart is currently empty.
                    </h3>
                    <p className="pb-7 title">
                      Start exploring now to add items to your cart.
                    </p>{" "}
                    <Link to="/products">
                      {" "}
                      <button className="outlined-btn">
                        <ManageSearchIcon /> Explore More
                      </button>
                    </Link>
                  </div>
                </Col>
              </Row>
            </Container>
          )}
        </>
      )}

      <Footer />
      {dialogBox.status && (
        <DialogBox
          message={dialogBox.message}
          closeMessage="OK"
          closeModal={closeModal}
        />
      )}
      {logindata.data.length !== 0
        ? deleteBox && (
            <DeleteBox
              closeMessage="CANCEL"
              closeModal={closeModal}
              deleteRecord={removeProduct}
              successMessage="Delete"
              message="Are you sure you want to remove this product from your enquiry cart?"
            />
          )
        : deleteBox && (
            <DeleteBox
              closeMessage="CANCEL"
              closeModal={closeModal}
              deleteRecord={() => {
                dispatch(removeTempCartData(deleteId));
                dispatch(updateCountByUser(1));

                setDeleteBox(false);
              }}
              successMessage="Delete"
              message="Are you sure you want to remove this product from your enquiry cart?"
            />
          )}
      {deleteAllBox && (
        <DeleteBox
          closeMessage="CANCEL"
          closeModal={closeModal}
          deleteRecord={() => {
            deleteAll();
          }}
          message="Are you sure you want to remove this products from your enquiry cart?"
          successMessage="Delete"
        />
      )}
    </div>
  );
};

export default ProductsList;
