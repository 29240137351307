import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Form,
  Table,
  Spinner,
} from "react-bootstrap";
import SideNav from "../Sidebar/SideNav";
import DialogBox from "../Common/DialogBox";
import ApiHelper from "../../utils/ApiHelper";
import DeleteBox from "../Common/DeleteBox";
import IconButton from '@mui/material/IconButton';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import ModeEditOutlineTwoToneIcon from '@mui/icons-material/ModeEditOutlineTwoTone';
import { useSelector } from "react-redux";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai"; // icons form react-icons
import { IconContext } from "react-icons";
import ReactPaginate from "react-paginate";

const UnitMaster = () => {
  const [unitName, setUnitName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [dialogBox, setDialogBox] = useState(false);
  const [updateDialogBox, setUpdateDialogBox] = useState(false);
  const [deleteDialogBox, setDeleteDialogBox] = useState(false);

  
  const [unitData, setUnitdata] = useState([]);
  const [deleteBox, setDeleteBox] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [updateId, setUpdateId] = useState(0);
  const logindata = useSelector((state) => state.loginreducer);
  const productPerPage = 10;

  const onValueChange = (e) => {
    const { name, value } = e.target;
    setUnitName(value);
    setErrors({ ...errors, [e.target.name]: "" });
  };
  const isValidate = () => {
    let errors = {};
    let isFormValid = true;

    if (unitName === "") {
      isFormValid = false;
      errors["unitName"] = "Please enter unit name";
    }

    setErrors(errors);
    return isFormValid;
  };
  const closeModal = () => {
    setDialogBox(false);
    setUpdateDialogBox(false);
    setDeleteDialogBox(false);
  };
  const onSubmitClick = async (e) => {
    e.preventDefault();
    if (isValidate()) {
      setIsLoading(true);
      const records = {
        unitName: unitName,
      };
      // const data
      await ApiHelper.postUnit(records,logindata.data.accessToken);
      setDialogBox(true);
      setIsLoading(false);
      setUnitName("");
      getData(1);
    }
  };

  useEffect(() => {
    window.scroll(0,0);
    getData(1);
  }, []);
  const getData = async (pageNo) => {
    ApiHelper.getUnit(pageNo,productPerPage,logindata.data.accessToken)
      .then((res) => {
        console.log(res, "data");
        setUnitdata(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onDeleteClick = async (id) => {
    setDeleteBox(true);
    setDeleteId(id);
  };
  const deleteRecords = () => {
    ApiHelper.deleteUnit(deleteId,logindata.data.accessToken).then((res) => {
      setDeleteBox(false);
      setDeleteDialogBox(true)
      getData(1);
    });
  };
  const onEditClick = (id) => {
    getDataById(id);
  };
  const getDataById = async (id) => {
    await ApiHelper.getUnitById(id,logindata.data.accessToken).then((res) => {
      setUnitName(res.unitName);
      setUpdateId(res.unitId);
    });
  };
  const onUpdateClick = async (e) => {
    e.preventDefault();
    
    setIsLoading(true);
    const rec = {
      unitId: updateId,
      unitName: unitName,
    };
    console.log(rec);
    await ApiHelper.updateUnit(updateId, rec,logindata.data.accessToken)
      .then((res) => {
        setUpdateId(0);
        setIsLoading(false);
        setUnitName("")
        setUpdateDialogBox(true);
        getData(1);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        alert("Something went wrong!");
      });
  };

  return (
    <div>
      <SideNav />
      <Container className="pt-4">
        <div>
          <h2 className="form-heading">Unit Master</h2>
        </div>
        <Row className="py-3">
          <Col>
            <Card className="admin-form-cards">
              <Card.Body>
                <Form>
                  <Form.Group
                    as={Row}
                    className="my-3"
                    controlId="formHorizontalEmail"
                  >
                    <Form.Label className="text-center" column sm={3}>
                      Unit Master Name
                    </Form.Label>
                    <Col sm={6}>
                      <div className="text-center">
                        <Form.Control
                          type="email"
                          placeholder="Enter Unit Name"
                          name="unitName"
                          value={unitName}
                          onChange={(e) => {
                            onValueChange(e);
                          }}
                        />
                        {errors.unitName && (
                          <span style={{ color: "red" }}>
                            {errors.unitName}
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col sm={3}>
                      <div className="text-center">
                        <Button
                          className="w-50"
                          onClick={(e) => {
                            updateId ? onUpdateClick(e) : onSubmitClick(e);
                          }}
                          disabled={isLoading}
                        >
                          {" "}
                          {isLoading && <Spinner />}
                          Submit
                        </Button>
                      </div>
                    </Col>
                  </Form.Group>
                  {dialogBox && (
                    <DialogBox
                      message="Unit saved successfully"
                      closeMessage="OK"
                      closeModal={closeModal}
                    />
                  )}
                   {updateDialogBox && (
                    <DialogBox
                      message="Unit updated successfully"
                      closeMessage="OK"
                      closeModal={closeModal}
                    />
                  )}
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <div className="py-4">
          <h2 className="form-heading">Unit Master List</h2>
        </div>
        <Row className="py-3">
          <Col>
            <Card className="admin-form-cards p-2">
              <Table bordered responsive>
                <thead>
                  <tr>
                    <th>Sr. No.</th>
                    <th>Unit Master</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {unitData.unitMaster && unitData.unitMaster.map((data, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{data.unitName}</td>
                      <td>
                        <IconButton
                          onClick={(e) => {
                            onEditClick(data.unitId);
                          }}
                        >
                          <ModeEditOutlineTwoToneIcon/>
                        </IconButton>
                        <IconButton
                          onClick={(e) => {
                            onDeleteClick(data.unitId);
                          }}
                        >
                          <DeleteTwoToneIcon/>
                        </IconButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
             
              {deleteBox && (
                <DeleteBox
                  closeMessage="CANCEL"
                  closeModal={closeModal}
                  deleteRecord={deleteRecords}
                  successMessage="Delete"
                  message="Are you sure you want to delete?"
                />
              )}
               {deleteDialogBox && (
                    <DialogBox
                      message="Unit deleted successfully"
                      closeMessage="OK"
                      closeModal={closeModal}
                    />
                  )}
            </Card>
          </Col>
        </Row>
        <Row className="py-3">
        {unitData.unitMaster && (
                  <ReactPaginate
                    containerClassName={"pagination"}
                    pageClassName={"page-item"}
                    activeClassName={"active"}
                    onPageChange={(event) => {
                      // onPageHandleChange(event.selected)

                      getData(event.selected + 1);
                    }}
                    pageCount={Math.ceil(
                      unitData.totalUnitMaster / productPerPage
                    )}
                    breakLabel="..."
                    previousLabel={
                      <IconContext.Provider
                        value={{ color: "#B8C1CC", size: "36px" }}
                      >
                        <AiFillLeftCircle />
                      </IconContext.Provider>
                    }
                    nextLabel={
                      <IconContext.Provider
                        value={{ color: "#B8C1CC", size: "36px" }}
                      >
                        <AiFillRightCircle />
                      </IconContext.Provider>
                    }
                  />
                )}
                </Row>
      </Container>
    </div>
  );
};

export default UnitMaster;
