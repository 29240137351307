import React, { useState, useEffect, useRef } from "react";
import { Container, Card, Form, Button, Spinner } from "react-bootstrap";
import ApiHelper from "../../utils/ApiHelper";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import DialogBox from "../Common/DialogBox";
import SideNav from "../Sidebar/SideNav";
import { Link, useNavigate, useParams } from "react-router-dom";

import {
  baseUrlAietplBackend,
  baseUrlAietplImage,
  baseUrlAietplThumbnailImage,
} from "../../utils/ApiUtils";
import { useSelector } from "react-redux";

import { currencyOptions } from "../Common/currencyOption";

const ProductMaster = () => {
  const params = useParams();
  console.log(params);
  const navigate = useNavigate();
  const [category, setCategory] = useState([]);
  const [units, setUnits] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [selectedCurrency, setSelectedCurrency] = useState();

  const [selectedUnit, setSelectedUnit] = useState(0);
  const [productData, setproductData] = useState({
    productName: "",
    productPrice: "",
    currency: "",
    productStock: "",
    productDesc: "",
    imagePath1: "",
    imagePath2: "",
    imagePath3: "",
    imagePath4: "",
  });
  const imageInputRef1 = useRef(null);
  const imageInputRef2 = useRef(null);
  const imageInputRef3 = useRef(null);
  const imageInputRef4 = useRef(null);
  const [img1File, setimg1File] = useState("");
  const [img2File, setimg2File] = useState("");
  const [img3File, setimg3File] = useState("");
  const [img4File, setimg4File] = useState("");
  const [deleteImages, setdeleteImages] = useState([]);
  const [error, setError] = useState({});
  const [dialogBox, setDialogBox] = useState({
    status: false,
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [fileNameData, setfileNameData] = useState([]);
  const [thumbnailFileNameData, setthumbnailFileNameData] = useState([]);
  // const [selectedImages, setselectedImages] = useState({
  //   image1: "",
  //   image2: "",
  //   image3: "",
  //   image4: "",
  // });
  const logindata = useSelector((state) => state.loginreducer);
  const editorRef = useRef();
  const [editorLoaded, setEditorLoaded] = useState(false);
  const { CKEditor, ClassicEditor } = editorRef.current || {};
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImageFiles, setSelectedImageFiles] = useState([]);

  // const onImageChange1 = (event) => {
  //   if (event.target.files && event.target.files[0]) {
  //     setproductData({
  //       ...productData,
  //       imagePath1: URL.createObjectURL(event.target.files[0]),
  //     });
  //     setimg1File(event.target.files[0]);
  //     setselectedImages({ ...selectedImages, image1: event.target.files[0] });
  //   }
  // };

  const onImageChange1 = (e) => {
    const files = e.target.files;
    const imagesArray = [];
    const imagesFileArray = [];

    // Validate file types and other constraints if needed
    if (files) {
      for (let i = 0; i < files?.length; i++) {
        const file = files[i];
        const isValidFileType = /\.(jpg|png)$/i.test(file.name);

        if (!isValidFileType) {
          setError({
            ...error,
            imagePath1: "Invalid file type. Please select JPG or PNG images.",
          });
          return;
        }
        imagesFileArray.push(file);
        // Add the file to the array
        imagesArray.push(URL.createObjectURL(file));
      }

      setSelectedImageFiles((prevImages) => [
        ...prevImages,
        ...imagesFileArray,
      ]);
      // Update the selected images by appending new images to the existing array
      setSelectedImages((prevImages) => [...prevImages, ...imagesArray]);
      setError({ ...error, imagePath1: "" });
    }
  };

  useEffect(() => {
    editorRef.current = {
      CKEditor: require("@ckeditor/ckeditor5-react").CKEditor, //Added .CKEditor
      ClassicEditor: require("@ckeditor/ckeditor5-build-classic"),
    };
    setEditorLoaded(true);
  }, []);
  const onImageChange2 = (event) => {
    if (event.target.files && event.target.files[0]) {
      setproductData({
        ...productData,
        imagePath2: URL.createObjectURL(event.target.files[0]),
      });
      setimg2File(event.target.files[0]);

      setSelectedImages({ ...selectedImages, image2: event.target.files[0] });
    }
  };
  const onImageChange3 = (event) => {
    if (event.target.files && event.target.files[0]) {
      setproductData({
        ...productData,
        imagePath3: URL.createObjectURL(event.target.files[0]),
      });
      setimg3File(event.target.files[0]);
      // setselectedImages({ ...selectedImages, image3: event.target.files[0] });
    }
  };
  const onImageChange4 = (event) => {
    if (event.target.files && event.target.files[0]) {
      setproductData({
        ...productData,
        imagePath4: URL.createObjectURL(event.target.files[0]),
      });
      setimg4File(event.target.files[0]);
      // setselectedImages({ ...selectedImages, image4: event.target.files[0] });
    }
  };

  const onCurrencyChange = (obj) => {
    alert("hi");
    console.log(obj, "obj");
    this.setState({ currency: obj });
    // this.state.errors.currency = "";
  };
  // const handleImageChange1 = (event) => {
  //   const file = event.target.files?.[0];
  //   setimgFile1(file);
  //   setselectedImages({ ...selectedImages, image1: file });
  //   setProductData({
  //     ...productData,
  //     imgPath: URL?.createObjectURL(event.target.files?.[0]),
  //   });
  //   setError({ ...Error, imgFile: "" });
  // };
  useEffect(() => {
    window.scroll(0, 0);
    getCategory();
    getUnit();
    if (params.id) {
      getDataById();
    }
  }, []);

  const getDataById = async () => {
    const getproductById = await ApiHelper.getProductById(params.id);
    console.log(getproductById, "getproductById");
    setproductData({
      ...productData,
      productName: getproductById.productName,
      productPrice: getproductById.productPrice,

      productStock: getproductById.stocks,
      productDesc: getproductById.productDescription,
    });
    setSelectedCategory(getproductById.productCategory.categoryId);
    setSelectedCurrency(getproductById.currency);

    setSelectedUnit(getproductById?.unitMaster?.unitId);
    setfileNameData(getproductById.imageFilenames);
    setthumbnailFileNameData(getproductById.thumbnailFilenames);
    // const imagesArray = [];

    // for (let i = 0; i < getproductById.thumbnailFilenames.length; i++) {
    //   // Add the file to the array
    //   imagesArray.push(
    //     baseUrlAietplThumbnailImage + "/" + getproductById.thumbnailFilenames[i]
    //   );
    // }

    // Update the selected images by appending new images to the existing array
    // setSelectedImages((prevImages) => [...prevImages, ...imagesArray]);
  };
  const getCategory = async () => {
    const productCategory = await ApiHelper.getCategory();
    let options = [];
    productCategory.forEach((element) => {
      options.push({
        value: element.categoryId,
        label: element.categoryName,
      });
    });
    setCategory(options);
  };
  const getUnit = async () => {
    const units = await ApiHelper.getUnitData(logindata.data.accessToken);
    let options = [];
    units.forEach((element) => {
      options.push({
        value: element.unitId,
        label: element.unitName,
      });
    });
    setUnits(options);
  };
  const onInputChange = (e) => {
    setproductData({ ...productData, [e.target.name]: e.target.value });
  };
  const validateForm = () => {
    let error = {};
    let formIsValid = true;

    if (!selectedCategory || selectedCategory === "") {
      formIsValid = false;

      error["selectedCategory"] = "Please Select Category";
    }
    if (!productData.productName || productData.productName === "") {
      formIsValid = false;

      error["productName"] = "Please Enter Product Name";
    }
    if (!productData.productPrice || productData.productPrice === "") {
      formIsValid = false;

      error["productPrice"] = "Please Enter Product Price";
    }
    if (!selectedCurrency || selectedCurrency === "") {
      formIsValid = false;

      error["selectedCurrency"] = "Please Select Currency";
    }

    if (!productData.productStock || productData.productStock === "") {
      formIsValid = false;

      error["productStock"] = "Please Enter Product Stock";
    }
    if (!productData.productDesc || productData.productDesc === "") {
      formIsValid = false;

      error["productDesc"] = "Please Enter Product Description";
    }
    if (!productData.productDesc || productData.productDesc === "") {
      formIsValid = false;

      error["productDesc"] = "Please Enter Product Description";
    }
    if (selectedImages?.length + fileNameData?.length===0) {
      formIsValid = false;

      error["imagePath1"] = "Please Select Product Images";
    }
    
    // if (!productData.imagePath1 || productData.imagePath1 === "") {
    //   formIsValid = false;

    //   error["imagePath1"] = "Please Select Product Image";
    // }

    setError(error);
    return formIsValid;
  };

  const onupdate = async (e) => {
    e.preventDefault();
    let current = new Date();
    let date = `${current.getFullYear()}-0${
      current.getMonth() + 1
    }-${current.getDate()}`;
    if (validateForm()) {
      setIsLoading(true);
      if (deleteImages?.length !== 0) {
        for (let i = 0; i < deleteImages?.length; i++) {
          const deleteImageFile = await ApiHelper.deleteImageFile(
            deleteImages[i].substring(0, deleteImages[i].lastIndexOf(".")),
            logindata.data.accessToken
          );
        }
      }
      if (selectedImageFiles?.length === 0) {
        const records = {
          productId: params.id,
          productName: productData.productName,
          productPrice: productData.productPrice,
          productCategory: {
            categoryId: selectedCategory,
          },
          unitMaster: {
            unitId: selectedUnit,
          },
          currency: selectedCurrency,
          productDescription: productData.productDesc,
          stocks: productData.productStock,
          // fileName1: fileNameData.fileName1,
          // fileName2: fileNameData.fileName2,
          // fileName3: fileNameData.fileName3,
          // fileName4: fileNameData.fileName4,
          // thumbnailFileName1: thumbnailFileNameData.thumbnailFileName1,
          // thumbnailFileName2: thumbnailFileNameData.thumbnailFileName2,
          // thumbnailFileName3: thumbnailFileNameData.thumbnailFileName3,
          // thumbnailFileName4: thumbnailFileNameData.thumbnailFileName4,
          imageFilenames: fileNameData,
          thumbnailFilenames: thumbnailFileNameData,
          cr_date: date,
        };
        const data = await ApiHelper.updateProductMaster(
          params.id,
          records,
          logindata.data.accessToken
        );
        setDialogBox({
          ...dialogBox,
          status: true,
          message: "Product Updated Successfully..!",
        });
        setIsLoading(false);
        navigate("/product-master-list")
        onReset();

        // window.scroll(0, 0);
        // if (data === "Already Exists..!") {
        //   setError({ ...Error, productName: "Already Exists..!" });
        //   setIsLoading(false);
        // } else {
        //   updateRecords1(e);
        // }
      } else {
        if (validateForm()) {
          setIsLoading(true);
          let filearr = fileNameData;
          let thumbnailfilearr = thumbnailFileNameData;

          const formData = new FormData();
          selectedImageFiles.forEach((image, index) => {
            formData.append(`files`, image);
          });

          const img = await ApiHelper.addMultiImageFile(
            formData,
            logindata.data.accessToken
          );
          filearr = [...filearr, ...img?.fileNames];
          thumbnailfilearr = [...thumbnailfilearr, ...img?.thumbnailsFileName];
          //           setfileNameData(filearr);
          // setthumbnailFileNameData(thumbnailfilearr);

          const records = {
            productId: params.id,
            productName: productData.productName,
            productPrice: productData.productPrice,
            productCategory: {
              categoryId: selectedCategory,
            },
            unitMaster: {
              unitId: selectedUnit,
            },
            currency: selectedCurrency,
            productDescription: productData.productDesc,
            stocks: productData.productStock,

            cr_date: date,
            imageFilenames: filearr,
            thumbnailFilenames: thumbnailfilearr,
          };

          const data = await ApiHelper.updateProductMaster(
            params.id,
            records,
            logindata.data.accessToken
          );
          setDialogBox({
            ...dialogBox,
            status: true,
            message:
              // "Thank you for registering with Shiplords. We have sent you an email for verification. Please check your spam folder if you do not see the verification email in your inbox.",
              "Product Updated Successfully..!",
          });
          setIsLoading(false);
          navigate("/product-master-list")
          onReset();

          // if (data === "Already Exists..!") {
          //   window.scroll(0, 0);
          //   setError({ ...Error, productName: "Already Exists..!" });
          //   setIsLoading(false);
          // } else {
          //   updateRecords1(e);
          // }
        }
      }
    }
  };
  const onSubmitClick = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);
      // const formData1 = new FormData();
      // formData1.append("files", img1File);
      // formData1.append("files", img2File);
      // formData1.append("files", img3File);
      // formData1.append("files", img4File);
      const formData = new FormData();
      selectedImageFiles.forEach((image, index) => {
        formData.append(`files`, image);
      });

      const img = await ApiHelper.addMultiImageFile(
        formData,
        logindata.data.accessToken
      );
      let current = new Date();
      let date = `${current.getFullYear()}-0${
        current.getMonth() + 1
      }-${current.getDate()}`;
      const record = {
        productName: productData.productName,
        productPrice: productData.productPrice,
        currency: selectedCurrency,
        productCategory: {
          categoryId: selectedCategory,
        },
        unitMaster: {
          unitId: selectedUnit,
        },
        productDescription: productData.productDesc,
        stocks: productData.productStock,
      
        imageFilenames: img.fileNames,
        thumbnailFilenames: img.thumbnailsFileName,
        cr_date: date,
      };
      const postProduct = await ApiHelper.postProductMaster(
        record,
        logindata.data.accessToken
      );
      setDialogBox({
        ...dialogBox,
        status: true,
        message:
          // "Thank you for registering with Shiplords. We have sent you an email for verification. Please check your spam folder if you do not see the verification email in your inbox.",
          "Product Added Successfully..!",
      });
     
      setIsLoading(false);
      navigate("/product-master-list")
      onReset();
    }
  };
  const closeModal = () => {
    setDialogBox({ ...dialogBox, status: false, message: "" });
  };
  const onReset = () => {
    setproductData({
      ...productData,
      productName: "",
      productPrice: "",
      // currency: "",
      productStock: "",
      productDesc: "",
      imagePath1: "",
      imagePath2: "",
      imagePath3: "",
      imagePath4: "",
    });
    setSelectedCurrency();
    setSelectedCategory(0);
    setSelectedUnit(0);
    setimg1File("");
    setimg2File("");
    setimg3File("");
    setimg4File("");
    if (imageInputRef1.current) {
      imageInputRef1.current.value = "";
    }
    if (imageInputRef2.current) {
      imageInputRef2.current.value = "";
    }
    if (imageInputRef3.current) {
      imageInputRef3.current.value = "";
    }
    if (imageInputRef4.current) {
      imageInputRef4.current.value = "";
    }
  };

  return (
    <div>
      <SideNav />
      <Container>
        <div className="pb-4">
          <h2 className="form-heading">Product Master</h2>
        </div>

        <div className="pb-5">
          <div className="d-flex justify-content-end pb-3">
            <Link to="/product-master-list">
              {" "}
              <button className="outlined-btn">View Products</button>
            </Link>
          </div>
          <Card className="admin-form-cards">
            <Card.Body>
              <Form
                className="row"
                onSubmit={params.id ? onupdate : onSubmitClick}
              >
                <Form.Group className="col-sm-4 mb-3" controlId="formGridState">
                  <Form.Label>Product Category</Form.Label>
                  <Form.Select
                    defaultValue="Choose..."
                    value={selectedCategory}
                    onChange={(e) => {
                      setSelectedCategory(parseInt(e.target.value));
                      setError({ ...error, selectedCategory: "" });
                    }}
                  >
                    <option>Choose...</option>
                    {category.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </Form.Select>

                  {error.selectedCategory && (
                    <span style={{ color: "red" }}>
                      {error.selectedCategory}
                    </span>
                  )}
                </Form.Group>
                <Form.Group className="col-sm-4 mb-3" controlId="formGridEmail">
                  <Form.Label>Product Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Product Name"
                    onChange={(e) => {
                      onInputChange(e);
                      setError({ ...error, productName: "" });
                    }}
                    value={productData.productName}
                    name="productName"
                  />
                  {error.productName && (
                    <span style={{ color: "red" }}>{error.productName}</span>
                  )}
                </Form.Group>
                <Form.Group className="col-sm-4 mb-3" controlId="formGridEmail">
                  <Form.Label>Product Price</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter Product Price"
                    onChange={(e) => {
                      onInputChange(e);
                      setError({ ...error, productPrice: "" });
                    }}
                    value={productData.productPrice}
                    name="productPrice"
                  />
                  {error.productPrice && (
                    <span style={{ color: "red" }}>{error.productPrice}</span>
                  )}
                </Form.Group>
                <Form.Group className="col-sm-4 mb-3" controlId="formGridEmail">
                  <Form.Label>Currency </Form.Label>
                  <Form.Select
                    defaultValue="Choose..."
                    value={selectedCurrency}
                    onChange={(e) => {
                      setSelectedCurrency(e.target.value);
                      setError({ ...error, selectedCurrency: "" });
                    }}
                    // onChange={(e) => onCurrencyChange}
                  >
                    <option>Choose...</option>
                    {currencyOptions.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </Form.Select>
                  {error.selectedCurrency && (
                    <span style={{ color: "red" }}>
                      {error.selectedCurrency}
                    </span>
                  )}
                </Form.Group>
                <Form.Group className="col-sm-4 mb-3" controlId="formGridEmail">
                  <Form.Label>Product Stock</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter stock here"
                    onChange={(e) => {
                      onInputChange(e);
                      setError({ ...error, productStock: "" });
                    }}
                    value={productData.productStock}
                    name="productStock"
                  />
                  {error.productStock && (
                    <span style={{ color: "red" }}>{error.productStock}</span>
                  )}
                </Form.Group>

                <Form.Group className="col-sm-4 mb-3" controlId="formGridEmail">
                  <Form.Label>Unit</Form.Label>
                  <Form.Select
                    defaultValue="Choose..."
                    value={selectedUnit}
                    onChange={(e) => setSelectedUnit(parseInt(e.target.value))}
                  >
                    <option>Choose...</option>
                    {units.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>

                <Form.Group
                  className="col-sm-12 mb-3"
                  controlId="formGridEmail"
                >
                  <Form.Label>Product Description</Form.Label>

                  {editorLoaded ? (
                    <CKEditor
                      editor={ClassicEditor}
                      data={productData.productDesc}
                      onReady={(editor) => {
                        // You can store the "editor" and use when it is needed.
                        console.log("Editor is ready to use!", editor);
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();

                        setproductData((prevState) => ({
                          ...prevState,
                          ["productDesc"]: data,
                        }));
                      }}
                      onBlur={(event, editor) => {
                        console.log("Blur.", editor);
                      }}
                      onFocus={(event, editor) => {
                        console.log("Focus.", editor);
                      }}
                    />
                  ) : null}
                  {error.productDesc && (
                    <span style={{ color: "red" }}>{error.productDesc}</span>
                  )}
                </Form.Group>

                <Form.Group className="col-sm-3 mb-3" controlId="formFile">
                  {/* <Form.Label>Product Images</Form.Label> */}
                  <label htmlFor="fileInput" className="custom-file-upload">
                    Choose Product Images <PermMediaIcon />
                  </label>
                  <input
                    id="fileInput"
                    type="file"
                    multiple
                    onChange={onImageChange1}
                    accept=".jpg, .png"
                    className="mb-1"
                    style={{ display: "none" }}
                  /><br/>
                    {error.imagePath1 && (
                    <span style={{ color: "red" }}>{error.imagePath1}</span>
                  )}
                  {/* <input
                    type="file"
                    multiple
                    onChange={(e) => {
                      onImageChange1(e);
                      setError({ ...error, imagePath1: "" });
                    }}
                    accept=".jpg, .png"
                    className="mb-1"
                    ref={imageInputRef1}
                  /> */}
                
                  {/* {(productData.imagePath1!=="https://api.aietpl.com/aietpl/null" && productData.imagePath1) && (
                    <img
                      alt="product preview"
                      src={productData.imagePath1}
                      className="img-fluid"
                    />
                  )} */}
                </Form.Group>
                {(selectedImages?.length > 0 || fileNameData?.length > 0) && (
                  <div>
                    <p>{`${selectedImages?.length + fileNameData?.length} ${
                      selectedImages?.length + fileNameData?.length === 1
                        ? "image"
                        : "images"
                    } selected`}</p>
                    <div className="row ">
                      {/* <div className="image-container"> */}

                      {fileNameData &&
                        fileNameData.map((image, index) => (
                          <div className="col-sm-1 mb-1 image-container">
                            <img
                              key={index}
                              alt={`product preview ${index + 1}`}
                              src={baseUrlAietplImage + "/" + image}
                              className="img-fluid "
                            />
                            <div class="overlay">
                              <span
                                className="delete-icon"
                                onClick={() => {
                                  // Get the corresponding image filename in fileNameData
                                  const fileNameToDelete = fileNameData[index];

                                  // Filter thumbnailFileNameData to remove the specified image
                                  const updatedThumbnailImages =
                                    thumbnailFileNameData.filter(
                                      (thumbnail) =>
                                        thumbnail !==
                                        `thumbnail${fileNameToDelete}`
                                    );

                                  // Filter fileNameData to remove the specified image
                                  const updatedImages = fileNameData.filter(
                                    (fileName) => fileName !== fileNameToDelete
                                  );

                                  // Update state with the filtered arrays
                                  setthumbnailFileNameData(
                                    updatedThumbnailImages
                                  );
                                  setfileNameData(updatedImages);

                                  // Add the deleted image to deleteImages array
                                  setdeleteImages((prevImages) => [
                                    ...prevImages,
                                    fileNameToDelete,
                                  ]);
                                }}
                              >
                                X
                              </span>
                            </div>
                          </div>
                        ))}

                      {selectedImages.map((image, index) => (
                        <div className="col-sm-1 mb-1 image-container">
                          <img
                            key={index}
                            alt={`product preview ${index + 1}`}
                            src={image}
                            className="img-fluid "
                          />
                          <div class="overlay">
                            <span
                              class="delete-icon"
                              onClick={() => {
                                {
                                  const updatedImages = [
                                    ...selectedImages.slice(0, index),
                                    ...selectedImages.slice(index + 1),
                                  ];

                                  setSelectedImages(updatedImages);
                                }
                              }}
                            >
                              X
                            </span>
                          </div>
                        </div>
                      ))}
                      {/* </div> */}
                    </div>
                  </div>
                )}
                {/* <Form.Group className="col-sm-3 mb-3" controlId="formFile">
                  <Form.Label>Image 2</Form.Label>
                  <input
                    type="file"
                    onChange={onImageChange2}
                    className="mb-1"
                    ref={imageInputRef2}
                    accept=".jpg, .png"
                  />
                  {(productData.imagePath2!=="https://api.aietpl.com/aietpl/null" && productData.imagePath2) && (
                    <img
                      alt="product preview"
                      src={productData.imagePath2}
                      className="img-fluid"
                    />
                  )}
                </Form.Group> */}
                {/* <Form.Group className="col-sm-3 mb-3" controlId="formFile">
                  <Form.Label>Image 3</Form.Label>
                  <input
                    type="file"
                    onChange={onImageChange3}
                    className="mb-1"
                    ref={imageInputRef3}
                    accept=".jpg, .png"
                  />
                  {(productData.imagePath3!=="https://api.aietpl.com/aietpl/null" && productData.imagePath3) && (
                    <img
                      alt="product preview"
                      src={productData.imagePath3}
                      className="img-fluid"
                    />
                  )}
                </Form.Group> */}
                {/* <Form.Group className="col-sm-3 mb-3" controlId="formFile">
                  <Form.Label>Image 4</Form.Label>
                  <input
                    type="file"
                    onChange={onImageChange4}
                    className="mb-1"
                    ref={imageInputRef4}
                    accept=".jpg, .png"
                  />
                  {(productData.imagePath4!=="https://api.aietpl.com/aietpl/null" && productData.imagePath4) && (
                    <img
                      alt="product preview"
                      src={productData.imagePath4}
                      className="img-fluid"
                    />
                  )}
                </Form.Group> */}
                <div className="row d-flex justify-content-center py-2">
                  <div className="col-sm-2">
                    <Button
                      type="submit"
                      className="w-100"
                      disabled={isLoading}
                    >
                      {isLoading && <Spinner />}SUBMIT
                    </Button>
                  </div>

                  <div className="col-sm-2">
                    <Button variant="secondary" className="w-100">
                      CANCEL
                    </Button>
                  </div>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </Container>
      {dialogBox.status && (
        <DialogBox
          message={dialogBox.message}
          closeMessage="OK"
          closeModal={closeModal}
        />
      )}
    </div>
  );
};

export default ProductMaster;
