import React, { useState, useEffect } from "react";
import TopNav from "../Home/TopNav";
import { Container, Row, Col, Form, InputGroup, Button, Spinner } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { AiOutlineMail, AiOutlineLock } from "react-icons/ai";
import Footer from "../Home/Footer";
import ApiHelper from "../../utils/ApiHelper";
import { useNavigate } from "react-router-dom";
import DialogBox from "../Common/DialogBox";
import { useDispatch, useSelector } from "react-redux";

import {
  cartcount,
  cartdetail,
  logindetail,
  resetCartItem,
} from "../Redux/action-creators";
const Login = () => {
  const cartdata = useSelector((state) => state.cartreducer);
  const [isLoading, setIsLoading] = useState(false);
   const params = new URLSearchParams(window.location.search);
   const productIdFromURL = params.get('wishlist');
   console.log(productIdFromURL,"productIdFromURL")
  const [data, setdata] = useState({
    email: "",
    password: "",
    isShown: false,
  });
  const [dialogBox, setDialogBox] = useState({
    status: false,
    message: "",
  });
  const dispatch = useDispatch();
  const history = useNavigate();
  const onInputChange = (e) => {
    setdata({ ...data, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    window.scroll(0, 0);
    dispatch(logindetail([]));
    dispatch(cartdetail([]));
    dispatch(cartcount(0));
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await ApiHelper.signin(data)
      .then((res) => {
        
        // dispatch(setUserInfo(res));
        console.log(res);
        if (res.roles[0] === "ROLE_ADMIN") {
          history("/dashboard");
        }
        if (res.roles[0] === "ROLE_USER") {
          if(cartdata.tempdata.length!==0){
          let promises = [];
          let current = new Date();
          let date = `${current.getFullYear()}-0${
            current.getMonth() + 1
          }-${current.getDate()}`;
          for (let i = 0; i < cartdata?.tempdata.length; i++) {
            const record = {
              quantity: cartdata?.tempdata[i].quantity,
              userId: res.id,
              productMaster: {
                productId: cartdata?.tempdata[i].productId,
              },
              crDate: date,
              detailedRequirement:cartdata?.tempdata[i].detailedRequirement
            };
            const addToEnquiry = ApiHelper.addToEnquiry(record,res.accessToken);
            promises.push(addToEnquiry);
          }
          Promise.all(promises).then(() => {
            history("/product-list");
            dispatch(resetCartItem());
            
          });
        }
        if(productIdFromURL){
          const records = {
            wishlisted: true,
            productMaster: {
              productId: productIdFromURL,
            },
            user: {
              id: res.id,
            },
          };
           ApiHelper.addToWishlist(records).then((wishlistres)=>{
            
           })
           .catch((error)=>{

           })

           history("/products");
        }
        else{
          history("/");
        }
      }
      
        dispatch(logindetail(res));
        setIsLoading(false);
      })
      .catch((Error) => {
        setDialogBox({
          ...dialogBox,
          status: true,
          message:
            // "Thank you for registering with Shiplords. We have sent you an email for verification. Please check your spam folder if you do not see the verification email in your inbox.",
            "Incorrect username or password. Please try again",
        });
      });
  };
  const closeModal = () => {
    setDialogBox({ ...dialogBox, status: false, message: "" });
    setIsLoading(false);
  };
  return (
    <>
      <TopNav />
      <div className="login-bg">
        <Container>
          <Row>
            <Col sm={6}>
              <div className="login-form animate__animated animate__bounceInLeft">
                <div className="pt-4 pb-4">
                  <h2 className="login-heading">Sign In to your Account</h2>
                </div>
                <Form className="px-5">
                  <Form.Group className="mb-3" controlId="formGroupEmail">
                    <Form.Label>Email address</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <AiOutlineMail className="login-icons" />
                      </InputGroup.Text>
                      <Form.Control
                      required
                      type="email"
                        placeholder="Username"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        value={data.email}
                        name="email"
                        onChange={onInputChange}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formGroupPassword">
                    <Form.Label>Password</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <AiOutlineLock className="login-icons" />
                      </InputGroup.Text>
                      <Form.Control
                      required
                        type={data.isShown ? "text" : "password"}
                        placeholder="Password"
                        aria-label="Password"
                        aria-describedby="basic-addon1"
                        value={data.password}
                        name="password"
                        onChange={onInputChange}
                      />
                    </InputGroup>
                    <Form.Check
                      label="Show Password"
                      aria-label="option 1"
                      type="checkbox"
                      checked={data.isShown}
                      onChange={() =>
                        setdata({
                          ...data,
                          isShown: !data.isShown,
                        })
                      }
                    />
                  </Form.Group>
                  <div>
                    <NavLink to={"/sign-up"}>
                      <p className="d-flex justify-content-end fgps-text">
                        Forget Password?
                      </p>
                    </NavLink>
                  </div>
                  <div className="pb-3">
                    <Button className="login-btn" onClick={handleLogin} disabled={isLoading}>
                      {isLoading && <Spinner />}
                      SIGN IN
                    </Button>
                  </div>
                  <div className="text-center pb-3">
                    <p className="p-info-white">
                      Don't have an Account?{" "}
                      <a href="/register" className="p-info-orange">
                        Register Now!
                      </a>{" "}
                    </p>
                  </div>
                </Form>
              </div>
            </Col>
            {/* <Col sm={6} className='text-center'>
                    <div className='animate__animated animate__bounceInRight'>
                        <img className='img-fluid login-img' src={login} alt="login-illustration" />
                    </div>
                
                </Col> */}
          </Row>
        </Container>
      </div>

      <Footer />
      {dialogBox.status && (
        <DialogBox
          message={dialogBox.message}
          closeMessage="OK"
          closeModal={closeModal}
        />
      )}
    </>
  );
};

export default Login;
