import React , {useEffect} from "react";
import TopNav from "./TopNav";
import { Container, Row, Col, Button, Card, Form } from "react-bootstrap";
import "animate.css";
import Footer from "./Footer";
import about from "../../assets/images/about-img.png";
import agro from "../../assets/images/agriculture.png";
import gdp from "../../assets/images/gdp.png";
import global from "../../assets/images/global-search.png";
import quality from "../../assets/images/quality-control.png";
import organic from "../../assets/images/organic.png";
import logistic from "../../assets/images/logistics.png";
import fssai from '../../assets/images/FSSAI.png'
import gst from '../../assets/images/gst.jpg'
import incorporation from '../../assets/images/certificate-of-incorporation.jpg'
import spice from '../../assets/images/Spices_Board_of_India_Logo.png'
import msme from '../../assets/images/msme.jpg'
import ice from '../../assets/images/ice.png'
import { AiOutlineArrowRight } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import {FaDotCircle} from 'react-icons/fa'
import {FiPhoneCall} from 'react-icons/fi'
import {AiOutlineMobile,AiOutlineMail} from 'react-icons/ai'
import {TfiWorld} from 'react-icons/tfi'
import {MdNavigateNext} from 'react-icons/md'
import {HiOutlineLocationMarker} from 'react-icons/hi'

import Aos from 'aos';
import 'aos/dist/aos.css';

const Landing = () => {

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <>
      <TopNav />
      <div>
        <section className="top-banner mb-4">
          <Container>
            <Row className="d-flex justify-content-center text-center">
              <Col sm={10} data-aos="fade-down">
                <div className="">
                  <h1 className="home-heading ">
                    Welcome to AIETPL
                  </h1>
                  <p className="p-orange ">
                    Your Gateway to high Quality Indian Products and
                    Commodities.
                  </p>
                  <p className="p-white">
                    At AIETPL, we specialize in exporting a wide range of
                    high-quality agro commodities, chemicals, and daily need
                    items to clients worldwide. With our expertise and
                    commitment to excellence, we aim to be your trusted partner
                    in the global export import market.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="pt-4">
          <Container>
            <div>
              <h2 className="text-center sub-heading">ABOUT US</h2>
            </div>
            <Row className="py-4">
              <Col sm={6}  data-aos="fade-right">
                <div className="py-4">
                  <p className="p-info">
                    AIETPL It was founded in the Year 1996 by the name ANT
                    International Exports (AIE), as a sole proprietorship
                    Entrepreneurship. Later in the Year 2016 It was reformed to
                    AIE Trade Private Limited as a Company to cater the various
                    business profiles.
                  </p>
                </div>
                <div>
                  <h4 className="h4-heading">Mission and Values</h4>
                  <div className="d-flex justify-content-start">
                  <span><FaDotCircle className="mx-2 icon-dot-orange"/></span>
                  <p className="p-info">
                    AIETPL prides itself for the win of trust of our clients
                    with our quality, service and time management.
                  </p>
                  </div>
                 
                  <div className="d-flex justify-content-start">
                  <span><FaDotCircle className="mx-2 icon-dot-orange"/></span>
                  <p className="p-info">
                    Passion for business runs in our veins.
                  </p>
                  </div>
                  <div className="d-flex justify-content-start">
                  <span><FaDotCircle className="mx-2 icon-dot-orange"/></span>
                  <p className="p-info">
                    We truly Value your association with us and our relationship
                    with you will always have the top priority.
                  </p>
                  </div>
                  <div className="d-flex justify-content-start">
                  <span><FaDotCircle className="mx-2 icon-dot-orange"/></span>
                  <p className="p-info">
                    Business ethics and principals form the core of this
                    Company.
                  </p>
                  </div>
                  <div className="d-flex justify-content-start">
                  <span><FaDotCircle className="mx-2 icon-dot-orange"/></span>
                  <p className="p-info">
                    Welcome to AIETPL to experience India and its services like
                    never before.
                  </p>
                  </div>

                  
                  
                 
                        </div>
                <div className="pt-3">
                  <NavLink to={"/about-us"}>
                  <button className="seemore-btn">
                    See More <AiOutlineArrowRight />
                  </button>
                  </NavLink>
                 
                </div>
              </Col>
              <Col
                sm={6}
                className="text-center" data-aos="fade-left">
                <div className="pt-4">
                  <img src={about} className="img-fluid about-img" />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="py-5">
          <Container>
             <div className="py-3">
              <h2 className="text-center sub-heading">AIETPL CERTIFICATES</h2>
            </div>
            <Row className="py-3">
            <div className="mb-3 col-md-2 col-sm-6">
            <Card className="certi-cards">
              
                <img className="img-fluid certi-img" src={fssai} alt="FSSAI" />
             
            </Card>
            </div>
            <div className="mb-3 col-md-2 col-sm-6">
            <Card className="certi-cards">
              
                <img className="img-fluid certi-img" src={gst} alt="FSSAI" />
              
            </Card>
            </div>
            <div className="mb-3 col-md-2 col-sm-6">
            <Card className="certi-cards">
              
                <img className="img-fluid certi-img" src={incorporation} alt="FSSAI" />
              
            </Card>
            </div>
            <div className="mb-3 col-md-2 col-sm-6">
            <Card className="certi-cards">
             
                <img className="img-fluid certi-img" src={spice} alt="FSSAI" />
              
            </Card>
            </div>
            <div className="mb-3 col-md-2 col-sm-6">
            <Card className="certi-cards">
             
                <img className="img-fluid certi-img" src={ice} alt="FSSAI" />
              
            </Card>
            </div>
            <div className="mb-3 col-md-2 col-sm-6">
            <Card className="certi-cards">
              
                <img className="img-fluid certi-img" src={msme} alt="FSSAI" />
             
            </Card>
            </div>
            </Row>
          </Container>
        </section>
       
        <section className="partner-export py-5 mb-5">
          <Container>
            <Row className="py-4">
              <Col sm={6} className="left-side-partner px-5 py-5" data-aos="zoom-in">
                <div>
                  <h2 className="text-start sub-heading-white">
                    PARTNER WITH AIETPL
                  </h2>
                </div>
                <div>
                  <p className="p-info-white1">
                    By choosing AIETPL as your export partner, you gain access
                    to an extensive range of agro commodities, Indian
                    commodities, and reliable export services.
                    <br />
                    Our goal is to help you succeed in your global business
                    endeavors while ensuring a smooth and efficient export
                    import experience.
                    <br />
                    Whether you are a distributor, retailer, or a business
                    looking to expand your product offerings, we are here to
                    support your growth.
                    <br />
                    Experience the finest agro commodity and Indian commodity
                    exports with AIETPL. Contact us today to discuss your
                    requirements and explore the possibilities of collaboration.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="exports pt-4 pb-3">
          <Container>
            <div>
              <h2 className="text-center sub-heading">EXPORTS</h2>
            </div>
            <Row className="pt-3">
              <Col sm={4} className="mb-3" >
                <Card className="export-cards" data-aos="flip-right">
                  <Card.Body className="card-body-border">
                    <div className="text-center export-img">
                      <img src={agro} alt="agro" />
                    </div>
                    <Card.Title className="text-center h4-heading">
                      Agro Commodity Exports
                    </Card.Title>
                    <Card.Text className="text-center p-info">
                      We take pride in offering a diverse selection of agro
                      commodities. Our product range includes premium spices,
                      fresh fruits, vegetables, tea, grounded spices, and much
                      more. From the vibrant flavors of Indian cuisine to
                      essential ingredients that elevate global culinary
                      ...<span className="text-span">See More</span>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={4} className="mb-3">
                <Card className="export-cards" data-aos="flip-right">
                  <Card.Body className="card-body-border">
                    <div className="text-center export-img">
                      <img src={gdp} alt="agro" />
                    </div>
                    <Card.Title className="text-center h4-heading">
                      Indian Commodities
                    </Card.Title>
                    <Card.Text className="text-center p-info">
                      Experience the essence of India with our range of Indian
                      commodities. We provide authentic and traditional products
                      that capture the rich cultural heritage of India. Whether
                      it's exquisite handicrafts, religious items, or unique
                      artifacts, our Indian commodities 
                      ...<span className="text-span">See More</span>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={4} className="mb-3">
                <Card className="export-cards" data-aos="flip-right">
                  <Card.Body className="card-body-border">
                    <div className="text-center export-img">
                      <img src={global} alt="agro" />
                    </div>
                    <Card.Title className="text-center h4-heading">
                      Reliable Global Reach
                    </Card.Title>
                    <Card.Text className="text-center p-info">
                      With a strong network and extensive experience in the
                      export industry, we operate in various countries and
                      regions across the globe. Our export services cater to
                      Africa, Europe, USA, Middle East, and Far East markets.
                      This wide-reaching presence allows us to connect clients
                      ...<span className="text-span">See More</span>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="pt-3">
              <Col sm={4} className="mb-3">
                <Card className="export-cards" data-aos="flip-right">
                  <Card.Body className="card-body-border">
                    <div className="text-center export-img">
                      <img src={quality} alt="agro" />
                    </div>
                    <Card.Title className="text-center h4-heading">
                      Uncompromising Quality and Value
                    </Card.Title>
                    <Card.Text className="text-center p-info">
                      At AIETPL, quality is our utmost priority. We source our
                      agro commodities, Indian commodities, and other products
                      from trusted suppliers who adhere to strict quality
                      standards. Our experienced team conducts rigorous quality
                      checks and inspections to ensure 
                      ...<span className="text-span">See More</span>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={4} className="mb-3">
                <Card className="export-cards" data-aos="flip-right">
                  <Card.Body className="card-body-border">
                    <div className="text-center export-img">
                      <img src={organic} alt="agro" />
                    </div>
                    <Card.Title className="text-center h4-heading">
                      Organic and Natural Products
                    </Card.Title>
                    <Card.Text className="text-center p-info">
                      We understand the growing demand for organic and natural
                      products worldwide. Therefore, we offer a selection of
                      organic and natural agro commodities, allowing you to
                      cater to health-conscious consumers. These products are
                      carefully sourced and certified to provide you with
                      ...<span className="text-span">See More</span>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={4} className="mb-3">
                <Card className="export-cards" data-aos="flip-right">
                  <Card.Body className="card-body-border">
                    <div className="text-center export-img">
                      <img src={logistic} alt="agro" />
                    </div>
                    <Card.Title className="text-center h4-heading">
                      Sourcing and Inland Logistics
                    </Card.Title>
                    <Card.Text className="text-center p-info">
                      In addition to our extensive product range, we offer
                      comprehensive sourcing services for clients seeking
                      specific items from India. Our deep understanding of the
                      Indian market and vast network of suppliers enable us to
                      locate and procure products according to your unique
                      requirements...
                      <span className="text-span">See More</span>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
       
      </div>
      <Footer />
    </>
  );
};

export default Landing;
