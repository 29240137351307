import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";
import TopNav from "./TopNav";
import Footer from "./Footer";
import { FiPhoneCall } from "react-icons/fi";
import { AiOutlineMobile, AiOutlineMail } from "react-icons/ai";
import { TfiWorld } from "react-icons/tfi";
import { HiOutlineLocationMarker } from "react-icons/hi";

import Aos from "aos";
import "aos/dist/aos.css";
import ApiHelper from "../../utils/ApiHelper";
import DialogBox from "../Common/DialogBox";

const Enquiry = () => {
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    emailId: "",
    contactNo: "",
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [dialogBox, setDialogBox] = useState(false);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  const onValueChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const validContact = (e) => {
    let number = e.target.value;
    const validPhone = RegExp(/^[6-9]\d{9}$/);
    return validPhone.test(number)
      ? setErrors({ ...errors, contactNo: "" })
      : setErrors({
          ...errors,
          contactNo: "Please Enter Valid Contact No",
        });
  };
  const validEmail = (e) => {
    let email = e.target.value;
    const validEmail = RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );
    return validEmail.test(email)
      ? setErrors({ ...errors, emailId: "" })
      : setErrors({ ...errors, emailId: "Please enter a valid email" });
  };
  const isValidate = () => {
    let errors = {};
    let isFormValid = true;
    let isValidPhone = RegExp(/^[6-9]\d{9}$/);
    let isValidEmail = RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );

    if (data.contactNo && !isValidPhone.test(data.contactNo)) {
      isFormValid = false;
      errors["contactNo"] = "Please enter valid contact no";
    }
    if (data.emailId && !isValidEmail.test(data.emailId)) {
      isFormValid = false;
      errors["emailId"] = "Please enter valid email id";
    }
    if (data.firstName === "") {
      isFormValid = false;
      errors["firstName"] = "Please enter first name";
    }
    if (data.lastName === "") {
      isFormValid = false;
      errors["lastName"] = "Please enter last name";
    }
    if (data.emailId === "") {
      isFormValid = false;
      errors["emailId"] = "Please enter email id";
    }
    if (data.contactNo === "") {
      isFormValid = false;
      errors["contactNo"] = "Please enter contact no";
    }
    if (data.message === "") {
      isFormValid = false;
      errors["message"] = "Please enter message";
    }
    setErrors(errors);
    return isFormValid;
  };
  const onSubmitClick = async (e) => {
    e.preventDefault();
    if (isValidate()) {
      setIsLoading(true);
      const records = {
        firstName: data.firstName,
        lastName: data.lastName,
        emailId: data.emailId,
        contactNo: data.contactNo,
        message: data.message,
      };
      try{
      // const data
      await ApiHelper.postContactUs(records);
      setDialogBox(true);
      setIsLoading(false);
      onReset();
      }
      catch(error){
        setIsLoading(false);
      }
    }
  };
  const closeModal = () => {
    setDialogBox(false);
  };
  const onReset = () => {
    setData({
      ...data,
      firstName: "",
      lastName: "",
      emailId: "",
      contactNo: "",
      message: "",
    });
  };

  return (
    <>
      <div>
        <TopNav />
        <section className="contact-us py-5">
          <Container>
            <Row>
              <Col sm={6} data-aos="fade-right">
                <div className="pb-5">
                  <h2 className="text-center sub-heading">SEND US A MESSAGE</h2>
                </div>
                <div className="px-5">
                  <Form className="row">
                    <Form.Group
                      className="col-sm-6 mb-3"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Tell us your Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="First Name"
                        name="firstName"
                        value={data.firstName}
                        onChange={(e) => {
                          onValueChange(e);
                        }}
                      />
                      {errors.firstName && (
                        <span style={{ color: "red" }}>{errors.firstName}</span>
                      )}
                    </Form.Group>
                    <Form.Group
                      className="col-sm-6 mb-3"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Tell us your Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Last Name"
                        name="lastName"
                        value={data.lastName}
                        onChange={(e) => {
                          onValueChange(e);
                        }}
                      />
                      {errors.lastName && (
                        <span style={{ color: "red" }}>{errors.lastName}</span>
                      )}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Enter your Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter your Email"
                        name="emailId"
                        value={data.emailId}
                        onChange={(e) => {
                          onValueChange(e);
                          validEmail(e);
                        }}
                      />
                      {errors.emailId && (
                        <span style={{ color: "red" }}>{errors.emailId}</span>
                      )}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Enter your Phone</Form.Label>
                      <Form.Control
                        type="number"
                        min={0}
                        placeholder="Enter your Phone "
                        name="contactNo"
                        value={data.contactNo}
                        onChange={(e) => {
                          onValueChange(e);
                          validContact(e);
                        }}
                      />
                      {errors.contactNo && (
                        <span style={{ color: "red" }}>{errors.contactNo}</span>
                      )}
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="formBasicPassword">
                      <Form.Label>Enter your Address</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={4}
                        type="text"
                        placeholder="Enter your Address "
                        name="message"
                        value={data.message}
                        onChange={(e) => {
                          onValueChange(e);
                        }}
                      />
                      {errors.message && (
                        <span style={{ color: "red" }}>{errors.message}</span>
                      )}
                    </Form.Group>
                    <Button
                      className="login-btn"
                      disabled={isLoading}
                      onClick={(e) => {
                        onSubmitClick(e);
                      }}
                    >
                      {isLoading && <Spinner />}SEND MESSAGE
                    </Button>
                    {dialogBox && (
                    <DialogBox
                      message="Thank you for contacting us!"
                      closeMessage="OK"
                      closeModal={closeModal}
                    />
                  )}
                  </Form>
                </div>
              </Col>
              <Col sm={6} className="contact-leftcol" data-aos="fade-left">
                <div className="contact-info">
                  <div className="">
                    {/* <h4 className="h4-heading-white my-4">Contact Information</h4> */}
                    <p className="p-info-orange pb-3">Let's Talk</p>
                    <div className="d-flex justify-content-start pb-2">
                      <FiPhoneCall className="my-1 footer-icons" />
                      <p className="mx-5 mb-0 p-info">+91 022 40158412</p>
                    </div>
                    <div className="d-flex justify-content-start pb-2">
                      <AiOutlineMobile className="my-1 footer-icons" />
                      <p className="mx-5 mb-0 p-info ">09820211638</p>
                    </div>
                    <p className="p-info-orange py-3">Email Us</p>

                    <div className="d-flex justify-content-start pb-2">
                      <AiOutlineMail className="my-1 footer-icons" />
                      <p className="mx-5 mb-0 p-info">biz@aietpl.com </p>
                    </div>
                    <p className="p-info-orange py-3">Visit Us</p>
                    <div className="d-flex justify-content-start pb-2">
                      <TfiWorld className="my-1 footer-icons" />
                      <p className="mx-5 mb-0 p-info">www.aietpl.com</p>
                    </div>
                    <p className="p-info-orange py-3">Address</p>
                    <div className="d-flex justify-content-start pb-2">
                      <HiOutlineLocationMarker className="my-1 footer-icons" />
                      <p className="mx-5 mb-0 p-info">
                        HD - 046, Oberoi Commerz II,
                        <br />
                        1st Floor CTS no 95, <br />
                        Off Western Express Highway, <br />
                        Oberoi Garden City, <br />
                        Goregaon East, Mumbai 400063.
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <Footer />
      </div>
    </>
  );
};

export default Enquiry;
