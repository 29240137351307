import React, { useState } from "react";
import {
  Container,
  Form,
  Row,
  Col,
  InputGroup,
  Button,
  Spinner,
} from "react-bootstrap";
import { AiOutlineMail, AiOutlineMobile } from "react-icons/ai";
import { MdOutlineProductionQuantityLimits } from "react-icons/md";
import { PiListNumbersBold, PiFolderStarDuotone } from "react-icons/pi";
import { SlCalender } from "react-icons/sl";

import TopNav from "./TopNav";
import Footer from "./Footer";
import ApiHelper from "../../utils/ApiHelper";
import DialogBox from "../Common/DialogBox";

const CallToAction = () => {
  const [data, setData] = useState({
    productName: "",
    quantity: 0,
    requiredDate: "",
    requirement: "",
    productDescription: "",
    emailId: "",
    contactNo: "",
    userId: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [dialogBox, setDialogBox] = useState(false);

  const onValueChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    setErrors({ ...errors, [e.target.name]: "" });
  };
  const isValidate = () => {
    let errors = {};
    let isFormValid = true;
    let isValidPhone = RegExp(/^[6-9]\d{9}$/);
    let isValidEmail = RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );

    if (data.contactNo && !isValidPhone.test(data.contactNo)) {
      isFormValid = false;
      errors["contactNo"] = "Please enter valid contact no";
    }
    if (data.emailId && !isValidEmail.test(data.emailId)) {
      isFormValid = false;
      errors["emailId"] = "Please enter valid email id";
    }
    if (data.productName === "") {
      isFormValid = false;
      errors["productName"] = "Please enter product name";
    }
    if (data.quantity === "") {
      isFormValid = false;
      errors["quantity"] = "Please enter quantity";
    }
    if (data.requiredDate === "") {
      isFormValid = false;
      errors["requiredDate"] = "Please select date";
    }
    if (data.requirement === "") {
      isFormValid = false;
      errors["requirement"] = "Please enter requirement";
    }
    if (data.productDescription === "") {
      isFormValid = false;
      errors["productDescription"] = "Please enter product description";
    }
    if (data.emailId === "") {
      isFormValid = false;
      errors["emailId"] = "Please enter email id";
    }
    if (data.contactNo === "") {
      isFormValid = false;
      errors["contactNo"] = "Please enter contact no";
    }
    setErrors(errors);
    return isFormValid;
  };

  const validContact = (e) => {
    let number = e.target.value;
    const validPhone = RegExp(/^[6-9]\d{9}$/);
    return validPhone.test(number)
      ? setErrors({ ...errors, contactNo: "" })
      : setErrors({
          ...errors,
          contactNo: "Please Enter Valid Contact No",
        });
  };
  const validEmail = (e) => {
    let email = e.target.value;
    const validEmail = RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );
    return validEmail.test(email)
      ? setErrors({ ...errors, emailId: "" })
      : setErrors({ ...errors, emailId: "Please enter a valid email" });
  };
  const onSubmitClick = async (e) => {
    e.preventDefault();
    if (isValidate()) {
      setIsLoading(true);
      const records = {
        productName: data.productName,
        quantity: data.quantity,
        requiredDate: data.requiredDate,
        requirement: data.requirement,
        productDescription: data.productDescription,
        emailId: data.emailId,
        contactNo: data.contactNo,
        userId: "",
      };
      // const data
      await ApiHelper.postEnquiry(records);
      setDialogBox(true);
      setIsLoading(false);
      onReset();
    }
  };
  const closeModal = () => {
    setDialogBox(false);
  };
  const onReset = () => {
    setData({
      ...data,
      productName: "",
      quantity: 0,
      requiredDate: "",
      requirement: "",
      productDescription: "",
      emailId: "",
      contactNo: "",
    });
  };

  return (
    <div>
      <TopNav />
      <div className="login-bg">
        <Container>
          <Row>
            <Col sm={8}>
              <div className="login-form animate__animated animate__bounceInLeft">
                <div className="pt-4 pb-4">
                  <h2 className="login-heading">
                    Want your Product Souced from <br />
                    India / Abroad
                  </h2>
                  <p className="text-center p-orange">
                    Please let us know more in brief
                  </p>
                </div>
                <Form className="row px-5">
                  <Form.Group
                    className="col-sm-6 mb-3"
                    controlId="formGroupEmail"
                  >
                    <Form.Label>Product Name</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <MdOutlineProductionQuantityLimits className="login-icons" />
                      </InputGroup.Text>
                      <Form.Control
                        placeholder="Enter Product Name"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        name="productName"
                        value={data.productName}
                        onChange={(e) => {
                          onValueChange(e);
                        }}
                      />
                    </InputGroup>
                    {errors.productName && (
                      <span style={{ color: "red" }}>{errors.productName}</span>
                    )}
                  </Form.Group>
                  <Form.Group
                    className="col-sm-6 mb-3"
                    controlId="formGroupPassword"
                  >
                    <Form.Label>Quantity Required</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <PiListNumbersBold className="login-icons" />
                      </InputGroup.Text>
                      <Form.Control
                        type="number"
                        min={0}
                        placeholder="Enter the Quantity You Need"
                        aria-label="Password"
                        aria-describedby="basic-addon1"
                        name="quantity"
                        value={data.quantity}
                        onChange={(e) => {
                          onValueChange(e);
                        }}
                      />
                    </InputGroup>
                    {errors.quantity && (
                      <span style={{ color: "red" }}>{errors.quantity}</span>
                    )}
                  </Form.Group>
                  <Form.Group
                    className="col-sm-6 mb-3"
                    controlId="formGroupEmail"
                  >
                    <Form.Label>Require by time</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <SlCalender className="login-icons" />
                      </InputGroup.Text>
                      <Form.Control
                        type="date"
                        placeholder="Username"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        name="requiredDate"
                        value={data.requiredDate}
                        onChange={(e) => {
                          onValueChange(e);
                        }}
                      />
                    </InputGroup>
                    {errors.requiredDate && (
                      <span style={{ color: "red" }}>
                        {errors.requiredDate}
                      </span>
                    )}
                  </Form.Group>
                  <Form.Group
                    className="col-sm-6 mb-3"
                    controlId="formGroupPassword"
                  >
                    <Form.Label>Specific Requirement</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <PiFolderStarDuotone className="login-icons" />
                      </InputGroup.Text>
                      <Form.Control
                        placeholder="Enter your requirement"
                        aria-label="Password"
                        aria-describedby="basic-addon1"
                        name="requirement"
                        value={data.requirement}
                        onChange={(e) => {
                          onValueChange(e);
                        }}
                      />
                    </InputGroup>
                    {errors.requirement && (
                      <span style={{ color: "red" }}>{errors.requirement}</span>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Product Description and Others</Form.Label>
                    <Form.Control
                      placeholder="Add Description here"
                      as="textarea"
                      rows={3}
                      name="productDescription"
                      value={data.productDescription}
                      onChange={(e) => {
                        onValueChange(e);
                      }}
                    />
                    {errors.productDescription && (
                      <span style={{ color: "red" }}>
                        {errors.productDescription}
                      </span>
                    )}
                  </Form.Group>
                  <Form.Group
                    className="col-sm-6 mb-3"
                    controlId="formGroupEmail"
                  >
                    <Form.Label>Email</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <AiOutlineMail className="login-icons" />
                      </InputGroup.Text>
                      <Form.Control
                        placeholder="Enter your Email"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        name="emailId"
                        value={data.emailId}
                        onChange={(e) => {
                          onValueChange(e);
                          validEmail(e);
                        }}
                      />
                    </InputGroup>
                    {errors.emailId && (
                      <span style={{ color: "red" }}>{errors.emailId}</span>
                    )}
                  </Form.Group>
                  <Form.Group
                    className="col-sm-6 mb-3"
                    controlId="formGroupPassword"
                  >
                    <Form.Label>Contact Number</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <AiOutlineMobile className="login-icons" />
                      </InputGroup.Text>
                      <Form.Control
                        placeholder="Enter your Number"
                        aria-label="Password"
                        aria-describedby="basic-addon1"
                        name="contactNo"
                        value={data.contactNo}
                        onChange={(e) => {
                          onValueChange(e);
                          validContact(e);
                        }}
                        type="number"
                        min={0}
                      />
                    </InputGroup>
                    {errors.contactNo && (
                      <span style={{ color: "red" }}>{errors.contactNo}</span>
                    )}
                  </Form.Group>
                  {/* <div>
        <NavLink  to={"/sign-up"}>
            <p className='d-flex justify-content-end fgps-text'>Forget Password?</p>
        </NavLink>
      </div> */}
                  <div className="pb-5">
                    <Button
                      type="submit"
                      className="login-btn"
                      onClick={(e) => {
                        onSubmitClick(e);
                      }}
                      disabled={isLoading}
                    >
                      {isLoading && <Spinner />} SUBMIT INFORMATION
                    </Button>
                  </div>
                  {dialogBox && (
                    <DialogBox
                      message="Enquiry sent to the admin!"
                      closeMessage="OK"
                      closeModal={closeModal}
                    />
                  )}
                </Form>
              </div>
            </Col>
            {/* <Col sm={6} className='text-center'>
                    <div className='animate__animated animate__bounceInRight'>
                        <img className='img-fluid login-img' src={login} alt="login-illustration" />
                    </div>
                
                </Col> */}
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default CallToAction;
