export const currencyOptions = [
  { value: 'USD', label: 'USD - United States Dollar' },
  { value: 'EUR', label: 'EUR - Euro' },
  { value: 'GBP', label: 'GBP - British Pound Sterling' },
  { value: 'JPY', label: 'JPY - Japanese Yen' },
  { value: 'AUD', label: 'AUD - Australian Dollar' },
  { value: 'CAD', label: 'CAD - Canadian Dollar' },
  { value: 'CHF', label: 'CHF - Swiss Franc' },
  { value: 'CNY', label: 'CNY - Chinese Yuan' },
  { value: 'SEK', label: 'SEK - Swedish Krona' },
  { value: 'NZD', label: 'NZD - New Zealand Dollar' },
  { value: 'MXN', label: 'MXN - Mexican Peso' },
  { value: 'SGD', label: 'SGD - Singapore Dollar' },
  { value: 'HKD', label: 'HKD - Hong Kong Dollar' },
  { value: 'NOK', label: 'NOK - Norwegian Krone' },
  { value: 'KRW', label: 'KRW - South Korean Won' },
  { value: 'TRY', label: 'TRY - Turkish Lira' },
  { value: 'INR', label: 'INR - Indian Rupee' },
  { value: 'BRL', label: 'BRL - Brazilian Real' },
  { value: 'ZAR', label: 'ZAR - South African Rand' },
  { value: 'RUB', label: 'RUB - Russian Ruble' },
  { value: 'AED', label: 'AED - United Arab Emirates Dirham' },
  { value: 'SAR', label: 'SAR - Saudi Riyal' },
  { value: 'QAR', label: 'QAR - Qatari Riyal' },
  { value: 'THB', label: 'THB - Thai Baht' },
  { value: 'IDR', label: 'IDR - Indonesian Rupiah' },
  { value: 'MYR', label: 'MYR - Malaysian Ringgit' },
  { value: 'PHP', label: 'PHP - Philippine Peso' },
  { value: 'EGP', label: 'EGP - Egyptian Pound' },
  { value: 'PLN', label: 'PLN - Polish Zloty' },
  { value: 'CZK', label: 'CZK - Czech Koruna' },
  { value: 'HUF', label: 'HUF - Hungarian Forint' },
  { value: 'ILS', label: 'ILS - Israeli New Shekel' },
  { value: 'DKK', label: 'DKK - Danish Krone' },
  { value: 'CLP', label: 'CLP - Chilean Peso' },
  { value: 'PKR', label: 'PKR - Pakistani Rupee' },
  { value: 'KWD', label: 'KWD - Kuwaiti Dinar' },
  { value: 'OMR', label: 'OMR - Omani Rial' },
  { value: 'BHD', label: 'BHD - Bahraini Dinar' },
  { value: 'JOD', label: 'JOD - Jordanian Dinar' },
  { value: 'LBP', label: 'LBP - Lebanese Pound' },
  { value: 'TWD', label: 'TWD - New Taiwan Dollar' },
  { value: 'NGN', label: 'NGN - Nigerian Naira' },
  { value: 'UAH', label: 'UAH - Ukrainian Hryvnia' },
  { value: 'CRC', label: 'CRC - Costa Rican Colón' },
  { value: 'LKR', label: 'LKR - Sri Lankan Rupee' },
  { value: 'NPR', label: 'NPR - Nepalese Rupee' },
  { value: 'TND', label: 'TND - Tunisian Dinar' },
  { value: 'PEN', label: 'PEN - Peruvian Sol' },
  { value: 'GEL', label: 'GEL - Georgian Lari' },
  { value: 'BDT', label: 'BDT - Bangladeshi Taka' },
  { value: 'VEF', label: 'VEF - Venezuelan Bolívar' },
  { value: 'MKD', label: 'MKD - Macedonian Denar' },
  { value: 'BGN', label: 'BGN - Bulgarian Lev' },
  { value: 'RON', label: 'RON - Romanian Leu' },
  { value: 'HRK', label: 'HRK - Croatian Kuna' },
  { value: 'ISK', label: 'ISK - Icelandic Króna' },
  { value: 'XOF', label: 'XOF - West African CFA Franc' },
  { value: 'GHS', label: 'GHS - Ghanaian Cedi' },
  { value: 'KES', label: 'KES - Kenyan Shilling' },
  { value: 'UGX', label: 'UGX - Ugandan Shilling' },
  { value: 'ZMW', label: 'ZMW - Zambian Kwacha' },
  { value: 'MAD', label: 'MAD - Moroccan Dirham' },
  { value: 'DZD', label: 'DZD - Algerian Dinar' },
  { value: 'KZT', label: 'KZT - Kazakhstani Tenge' },
  { value: 'TMT', label: 'TMT - Turkmenistani Manat' },
  { value: 'UZS', label: 'UZS - Uzbekistani Som' },
  { value: 'BYN', label: 'BYN - Belarusian Ruble' },
  { value: 'AMD', label: 'AMD - Armenian Dram' },
  { value: 'AZN', label: 'AZN - Azerbaijani Manat' },
  { value: 'DOP', label: 'DOP - Dominican Peso' },
  { value: 'JMD', label: 'JMD - Jamaican Dollar' },
  { value: 'BSD', label: 'BSD - Bahamian Dollar' },
  { value: 'BBD', label: 'BBD - Barbadian Dollar' },
  { value: 'BZD', label: 'BZD - Belize Dollar' },
  { value: 'BND', label: 'BND - Brunei Dollar' },
  { value: 'FJD', label: 'FJD - Fijian Dollar' },
  { value: 'GYD', label: 'GYD - Guyanese Dollar' },
  { value: 'KID', label: 'KID - Kiribati Dollar' },
  { value: 'LRD', label: 'LRD - Liberian Dollar' },
  { value: 'NAD', label: 'NAD - Namibian Dollar' },
  { value: 'NZD', label: 'NZD - New Zealand Dollar' },
  { value: 'SBD', label: 'SBD - Solomon Islands Dollar' },
  { value: 'SRD', label: 'SRD - Surinamese Dollar' },
  { value: 'TOP', label: 'TOP - Tongan Paʻanga' },
  { value: 'TTD', label: 'TTD - Trinidad and Tobago Dollar' },
  { value: 'TVD', label: 'TVD - Tuvaluan Dollar' },
  { value: 'VUV', label: 'VUV - Vanuatu Vatu' },
  { value: 'WST', label: 'WST - Samoan Tala' },
  { value: 'XPF', label: 'XPF - CFP Franc' },
  { value: 'CUP', label: 'CUP - Cuban Peso' },
  { value: 'KPW', label: 'KPW - North Korean Won' },
  { value: 'SYP', label: 'SYP - Syrian Pound' },
  { value: 'MRU', label: 'MRU - Mauritanian Ouguiya' },
  { value: 'CHE', label: 'CHE - WIR Euro (complementary currency)' },
  { value: 'CHW', label: 'CHW - WIR Franc (complementary currency)' },
  { value: 'CLF', label: 'CLF - Unidad de Fomento (funds code)' },
  { value: 'MXV', label: 'MXV - Mexican Unidad de Inversion (UDI)' },
  { value: 'USN', label: 'USN - United States dollar (next day) (funds code)' },
  { value: 'COU', label: 'COU - Unidad de Valor Real (UVR) (funds code)' },
  { value: 'ZWL', label: 'ZWL - Zimbabwean Dollar' },
]