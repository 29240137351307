// export const baseUrlAietplBackend = "http://localhost:9090/aietpl";
// export const baseUrlAietplImage = "http://localhost:9090/aietpl/public-api";
// export const baseUrlAietplThumbnailImage = "http://localhost:9090/aietpl/public-api/ImageService";
export const baseUrlAietplBackend = "https://api.aietpl.com/aietpl";
export const baseUrlAietplImage = "https://api.aietpl.com/aietpl/public-api";
export const baseUrlAietplThumbnailImage = "https://api.aietpl.com/aietpl/public-api/ImageService";

export default class Apis {
  static customerRegister = baseUrlAietplBackend + "/public-api/signup"; 
  static signin = baseUrlAietplBackend + "/public-api/signin";

  static postContactUs = baseUrlAietplBackend + `/public-api/ContactUs`;
  static postEnquiry = baseUrlAietplBackend + `/public-api/Enquiry`;

  static getUnitData = baseUrlAietplBackend + "/unit";
  static getUnit = (pageNo,pageSize) => {
    return baseUrlAietplBackend + `/unit/${pageNo}/${pageSize}`;
  };
  static postUnit = baseUrlAietplBackend + `/unit`;
  static deleteUnit = (id) => {
    return baseUrlAietplBackend + `/unit/${id}`;
  };
  static getUnitById = (id) => {
    return baseUrlAietplBackend + `/unit/${id}`;
  };
  static updateUnit = (id) => {
    return baseUrlAietplBackend + `/unit/${id}`;
  };
  static postCategory = baseUrlAietplBackend + `/productCategory`;
  static getCategory = baseUrlAietplBackend + "/public-api/productCategory";
  static getCategoryListByPagination = (pageNo,pageSize) => {
    return baseUrlAietplBackend + `/productCategory/${pageNo}/${pageSize}`;
  };
  static deleteCategory = (id) => {
    return baseUrlAietplBackend + `/productCategory/${id}`;
  };
  static getCategoryById = (id) => {
    return baseUrlAietplBackend + `/productCategory/${id}`;
  };
  static updateCategory = (id) => {
    return baseUrlAietplBackend + `/productCategory/${id}`;
  };
  static addImageFile = baseUrlAietplBackend + `/addImageFile`;
  static addMultiImageFile = baseUrlAietplBackend + `/addMultiImageFile`;
  static deleteImageFile = (fileName) => {
    return baseUrlAietplBackend + `/deleteFiles/${fileName}`;
  };
  static postProductMaster = baseUrlAietplBackend + `/ProductMaster`;
  static getProductsListByPagination = (pageNo,pageSize) => {
    return baseUrlAietplBackend + `/ProductMaster/${pageNo}/${pageSize}`;
  };
  static getProductsList = baseUrlAietplBackend + `/public-api/ProductMaster`;
  
 
  static getProductById = (id) => {
    return baseUrlAietplBackend + `/public-api/ProductMaster/${id}`;
  };
  static updateProductMaster = (id) => {
    return baseUrlAietplBackend + `/ProductMaster/${id}`;
  };
  static deleteProductMaster = (id) => {
    return baseUrlAietplBackend + `/ProductMaster/${id}`;
  };
  static addToEnquiry = baseUrlAietplBackend + `/addToEnquiry`;
  static getCartByUserId = (id) => {
    return baseUrlAietplBackend + `/addToEnquiry/${id}`;
  };
  static updateEnquiryQuantity = (id) => {
    return baseUrlAietplBackend + `/addToEnquiry/${id}`;
  };
  static postproductEnquiry = baseUrlAietplBackend + `/productEnquiry`;
  static deleteCartProductById = (id) => {
    return baseUrlAietplBackend + `/deleteCartProductById/${id}`;
  };
  static deleteMultipleEnquiry =
    baseUrlAietplBackend + `/public-api/deleteMultipleEnquiry`;
  static deleteAllEnquiryCart = baseUrlAietplBackend + `/deleteAllEnquiryCart`;

  
  static getContactData = (pageNo,pageSize) => {
    return baseUrlAietplBackend + `/ContactUs/${pageNo}/${pageSize}`;
  };
 
  static getRequirementData = (pageNo,pageSize) => {
    return baseUrlAietplBackend + `/Enquiry/${pageNo}/${pageSize}`;
  };
  
  static getproductEnquiry = (pageNo,pageSize) => {
    return baseUrlAietplBackend + `/productEnquiry/${pageNo}/${pageSize}`;
  };
  static getproductEnquiryByUserId = (id) => {
    return baseUrlAietplBackend + `/productEnquiryByUserId/${id}`;
  };
  static getproductEnquiryByEnquiryId = (id) => {
    return baseUrlAietplBackend + `/productEnquiryById/${id}`;
  };
  static addPdfFile = baseUrlAietplBackend + `/addPdfFile`;
  static replyProductEnquiry = () => {
    return baseUrlAietplBackend + `/productEnquiryReply`;
  };
  static productEnquiryMail=baseUrlAietplBackend+"/productEnquiryMail";
  static productEnquiryReplyMail=baseUrlAietplBackend+"/productEnquiryReplyMail";
  
  static getWishlistedProduct = baseUrlAietplBackend + "/Wishlist";
  static addToWishlist = baseUrlAietplBackend + `/Wishlist`;
  static removeFromWishlist = (id) => {
    return baseUrlAietplBackend + `/Wishlist/${id}`;
  };
  static getWishlistedProductByUserId = (userId) => {
    return baseUrlAietplBackend + `/WishlistByUser/${userId}`;
  };
 
 
  
}
