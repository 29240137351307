import Apis from "./ApiUtils";
import AxiosFetchUtils, {
  failureHandler,
  successHandler,
} from "./AxiosFetchUtils";

const axios = AxiosFetchUtils.getInstance();

const ApiHelper = {
  customerRegister: (registerData) => {
    return axios
      .post(Apis.customerRegister, registerData)
      .then(successHandler, failureHandler);
  },
  signin: (logininfo) => {
    return axios
      .post(Apis.signin, logininfo)
      .then(successHandler, failureHandler);
  },
  postContactUs: (data) => {
    return axios
      .post(Apis.postContactUs, data)
      .then(successHandler, failureHandler);
  },

  postEnquiry: (data) => {
    return axios
      .post(Apis.postEnquiry, data)
      .then(successHandler, failureHandler);
  },

  postUnit: (data,accessToken) => {
    return axios.post(Apis.postUnit, data,{
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }).then(successHandler, failureHandler);
  },
  getUnitData: (accessToken) => {
    return axios.get(Apis.getUnitData,{
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }).then(successHandler, failureHandler);
  },
  getUnit: (pageNo,pageSize,accessToken) => {
    return axios.get(Apis.getUnit(pageNo,pageSize),{
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }).then(successHandler, failureHandler);
  },

  deleteUnit: (id,accessToken) => {
    return axios
      .delete(Apis.deleteUnit(id),{
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(successHandler, failureHandler);
  },
  getUnitById: (id,accessToken) => {
    return axios.get(Apis.getUnitById(id),{
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }).then(successHandler, failureHandler);
  },
  updateUnit: (id, data,accessToken) => {
    return axios
      .put(Apis.updateUnit(id), data,{
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(successHandler, failureHandler);
  },

  postCategory: (data,accessToken) => {
    return axios
      .post(Apis.postCategory, data,{
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(successHandler, failureHandler);
  },
  getCategory: () => {
    return axios.get(Apis.getCategory).then(successHandler, failureHandler);
  },
  getCategoryListByPagination: (pageNo,pageSize,accessToken) => {
    return axios.get(Apis.getCategoryListByPagination(pageNo,pageSize),{
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }).then(successHandler, failureHandler);
  },
  deleteCategory: (id,accessToken) => {
    return axios
      .delete(Apis.deleteCategory(id),{
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(successHandler, failureHandler);
  },
  getCategoryById: (id,accessToken) => {
    return axios
      .get(Apis.getCategoryById(id),{
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(successHandler, failureHandler);
  },
  updateCategory: (id, data,accessToken) => {
    return axios
      .put(Apis.updateCategory(id), data,{
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(successHandler, failureHandler);
  },
  addImageFile: (imgFile,accessToken) => {
    return axios
      .post(Apis.addImageFile, imgFile,{
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(successHandler, failureHandler);
  },
  deleteImageFile: (fileName,accessToken) => {
    return axios
      .delete(Apis.deleteImageFile(fileName),{
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(successHandler, failureHandler);
  },
  addMultiImageFile: (imgFile,accessToken) => {
    return axios
      .post(Apis.addMultiImageFile, imgFile,{
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(successHandler, failureHandler);
  },
  postProductMaster: (data,accessToken) => {
    return axios.post(Apis.postProductMaster, data,{
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },
  getProductsListByPagination: (pageNo,pageSize,accessToken) => {
    return axios.get(Apis.getProductsListByPagination(pageNo,pageSize),{
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }).then(successHandler, failureHandler);
  },
  getProductsList: () => {
    return axios.get(Apis.getProductsList).then(successHandler, failureHandler);
  },
 
  getProductById: (id) => {
    return axios
      .get(Apis.getProductById(id))
      .then(successHandler, failureHandler);
  },
  updateProductMaster: (id, data,accessToken) => {
    return axios
      .put(Apis.updateProductMaster(id), data,{
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(successHandler, failureHandler);
  },
  deleteProductMaster: (id,accessToken) => {
    return axios
      .delete(Apis.deleteProductMaster(id),{
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(successHandler, failureHandler);
  },
  addToEnquiry: (data,accessToken) => {
    return axios
      .post(Apis.addToEnquiry, data,{
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(successHandler, failureHandler);
  },
  getCartByUserId: (id,accessToken) => {
    return axios
      .get(Apis.getCartByUserId(id),{
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
      }
      })
      .then(successHandler, failureHandler);
  },

  updateEnquiryQuantity: (id, data,accessToken) => {
    console.log(accessToken);
    return axios
      .put(Apis.updateEnquiryQuantity(id), data,{
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
      }
      )
      .then(successHandler, failureHandler);
  },
  postproductEnquiry: (data,accessToken) => {
    return axios
      .post(Apis.postproductEnquiry, data,{
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
      }
      })
      .then(successHandler, failureHandler);
  },
  deleteCartProductById: (id,accessToken) => {
    return axios
      .delete(Apis.deleteCartProductById(id),{
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
      }
      })
      .then(successHandler, failureHandler);
  },
  deleteMultipleEnquiry: (data) => {
    return axios
      .delete(Apis.deleteMultipleEnquiry, data)
      .then(successHandler, failureHandler);
  },
 
  getContactData: (pageNo,pageSize,accessToken) => {
    console.log(accessToken);  // Check if this prints the access token
    return axios.get(Apis.getContactData(pageNo,pageSize), {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
    })
    .then(successHandler, failureHandler);
  },
  getRequirementData: (pageNo,pageSize,accessToken) => {
    console.log(accessToken);  // Check if this prints the access token
    return axios.get(Apis.getRequirementData(pageNo,pageSize), {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
    })
    .then(successHandler, failureHandler);
  },
  

  getproductEnquiry: (pageNo,pageSize,accessToken) => {
    return axios
      .get(Apis.getproductEnquiry(pageNo,pageSize), {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        }
      })
      .then(successHandler, failureHandler);
  },
  getproductEnquiryByUserId: (id,accessToken) => {
    return axios
      .get(Apis.getproductEnquiryByUserId(id),{
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
      }
      })
      .then(successHandler, failureHandler);
  },
  getproductEnquiryByEnquiryId: (id,accessToken) => {
    return axios
      .get(Apis.getproductEnquiryByEnquiryId(id),{
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
      }
      })
      .then(successHandler, failureHandler);
  },
  addPdfFile: (pdfFile,accessToken) => {
    return axios
      .post(Apis.addPdfFile, pdfFile,{
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${accessToken}`,
        },
      })
      .then(successHandler, failureHandler);
  },
  productEnquiryMail: (formData,accessToken) => {
    return axios
      .post(Apis.productEnquiryMail, formData,{
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(successHandler, failureHandler);
  },
  productEnquiryReplyMail: (formData,accessToken) => {
    return axios
      .post(Apis.productEnquiryReplyMail, formData,{
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(successHandler, failureHandler);
  },
  replyProductEnquiry: (formData,accessToken) => {
    return axios
      .put(Apis.replyProductEnquiry(), formData,{
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(successHandler, failureHandler);
  },
  getWishlistedProduct: () => {
    return axios.get(Apis.getWishlistedProduct).then(successHandler, failureHandler);
  },
  
  getWishlistedProductByUserId: (userId,accessToken) => {
    return axios
      .get(Apis.getWishlistedProductByUserId(userId),{
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
      }
      })
      .then(successHandler, failureHandler);
  },
  addToWishlist: (data,accessToken) => {
    return axios
      .post(Apis.addToWishlist, data,{
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
      }
      })
      .then(successHandler, failureHandler);
  },

  removeFromWishlist: (id,accessToken) => {
    return axios
      .delete(Apis.removeFromWishlist(id),{
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
      }
      })
      .then(successHandler, failureHandler);
  },
  deleteAllEnquiryCart: (accessToken) => {
    return axios
      .delete(Apis.deleteAllEnquiryCart,{
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(successHandler, failureHandler);
  },
 
};

export default ApiHelper;
