import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Table } from "react-bootstrap";
import SideNav from "../Sidebar/SideNav";
import ApiHelper from "../../utils/ApiHelper";
import { useSelector } from "react-redux";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai"; // icons form react-icons
import { IconContext } from "react-icons";
import ReactPaginate from "react-paginate";

const EnquiryList = () => {
  const [allData, setAlldata] = useState([]);
  const logindata = useSelector((state) => state.loginreducer);
  const productPerPage = 10;

  useEffect(() => {
    window.scroll(0,0);
    getData(1);
  }, []);
  const getData = async (pageNo) => {
    ApiHelper.getContactData(pageNo,productPerPage,logindata.data.accessToken)
      .then((res) => {
        console.log(res, "data");
        setAlldata(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <SideNav />
      <Container className="pt-4">
        <div className="py-4">
          <h2 className="form-heading">Contact Us List</h2>
        </div>
        <Row className="py-3">
          <Col>
            <Card className="admin-form-cards p-2">
              <Table bordered responsive>
                <thead>
                  <tr>
                    <th>Sr. No.</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email Id</th>
                    <th>Contact No</th>
                    <th>Message</th>
                  </tr>
                </thead>
                <tbody>
                  {allData.contact && allData.contact.map((data, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{data.firstName}</td>
                      <td>{data.lastName}</td>
                      <td>{data.emailId}</td>
                      <td>{data.contactNo}</td>
                      <td>{data.message}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
        <Row className="py-3">
          <Col>
        {allData.contact && (
                  <ReactPaginate
                    containerClassName={"pagination"}
                    pageClassName={"page-item"}
                    activeClassName={"active"}
                    onPageChange={(event) => {
                      // onPageHandleChange(event.selected)

                      getData(event.selected + 1);
                    }}
                    pageCount={Math.ceil(
                      allData.totalCount / productPerPage
                    )}
                    breakLabel="..."
                    previousLabel={
                      <IconContext.Provider
                        value={{ color: "#B8C1CC", size: "36px" }}
                      >
                        <AiFillLeftCircle />
                      </IconContext.Provider>
                    }
                    nextLabel={
                      <IconContext.Provider
                        value={{ color: "#B8C1CC", size: "36px" }}
                      >
                        <AiFillRightCircle />
                      </IconContext.Provider>
                    }
                  />
                )}
                  </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EnquiryList;
