import React, { useState, useEffect } from "react";
import { Container, Table, TableRow } from "@mui/material";
import ApiHelper from "../../utils/ApiHelper";
import { baseUrlAietplBackend, baseUrlAietplImage, baseUrlAietplThumbnailImage } from "../../utils/ApiUtils";
import { TableCell } from "@mui/material";
import { IconButton } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import { Collapse } from "@mui/material";
import { Box } from "@mui/material";
import { TableHead } from "@mui/material";
import { TableBody } from "@mui/material";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Grid } from "@mui/material";
import { TableContainer } from "@mui/material";
import { Paper } from "@mui/material";


// import ReplyToEnquiry from "./ReplyToEnquiry";
import { Modal, Spinner } from "react-bootstrap";

import SideNav from "../Sidebar/SideNav";
import TopNav from "../Home/TopNav";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";



function Row(props) {
  const { row, updateParentState } = props;
  const [open, setOpen] = useState(false);

  const [isReplied, setisReplied] = useState(false);
  const history = useNavigate();
  const NumberSeperator = (input) => {
    return new Intl.NumberFormat("en-IN", { minimumFractionDigits: 2 }).format(
      input
    );
  };
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell width={"1%"}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {/* <TableCell  width={'10%'}>
          {row.name}
        </TableCell> */}
        <TableCell width={"15%"}>{row.enquiryNo}</TableCell>
        <TableCell width={"20%"}>{row.crDate}</TableCell>
        <TableCell width={"20%"}>{row.fullName}</TableCell>
        {/* <TableCell align="right" width={"15%"}>
          {row.emailId}
        </TableCell> */}
        {/* <TableCell align="right" width={"10%"}>
          {row.contactNo}
        </TableCell> */}
        <TableCell>{row.replied===false?
        <span>Pending...</span>:
        <span>Replied </span>
      }</TableCell>
      {row.replied===true &&
       <TableCell width={"15%"}><VisibilityOutlinedIcon onClick={()=>history("/productenquiryreplies/"+row.productEnquiryId)}/></TableCell>}
     
       
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: "2px", paddingTop: 0 }}
          colSpan={7}
          className="bg-slate-100"
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
             

              <Table
                size="medium"
                aria-label="purchases"
                id="order-details"
                className="bg-white my-6"
              >
                <TableHead>
                  <TableRow>
                    <TableCell width={"25%"}>Product Image</TableCell>
                    <TableCell width={"25%"}>Product Name</TableCell>
                    <TableCell width={"50%"}>Product Price</TableCell>
                    <TableCell align="right" width={"10%"}>
                      Quantity
                    </TableCell>
                    {/* <TableCell align="right" width={'15%'}>Total price (₹)</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.productEnquiryDetails.map(
                    (historyRow, index) =>
                      
                        <TableRow key={index}>
                          {/* <TableCell component="th" scope="row"> */}
                          <TableCell>
                            <img
                              src={
                                baseUrlAietplThumbnailImage +"/"+
                                historyRow.productMaster
                                  .thumbnailFilenames[0]
                              }
                              width={45}
                              height={45}
                              alt="Product Name"
                              title=""
                              className="max-w-sm rounded border bg-white p-1"
                            />
                          </TableCell>
                          <TableCell>
                            {
                              historyRow.productMaster
                                .productName
                            }
                          </TableCell>

                          {/* <TableCell>{historyRow.enquiryText}</TableCell> */}
                          <TableCell> <span>&#x20b9;</span>{" "}{NumberSeperator(historyRow.productMaster?.productPrice)}/-</TableCell>
                          <TableCell>{historyRow.productQuantity}</TableCell>
                        </TableRow>
                      
                  )}
                </TableBody>
              </Table>
              <Grid container className="order-info">
                {/* <Grid item md={6} sm={12} className="p-2">
                  <p>Address</p>
                 
                  {row.address1}, {row.address2}, {row.address3}, {row.pincode}
                </Grid> */}
                <Grid item md={12} sm={12} className="p-2">
                  <p>Description:</p>
                  {row.description}
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const ProductEnquiries = () => {
  const [enquiryList, setenquiryList] = useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [enquiryId, setenquiryId] = useState(0);
  const [enquiryDetail, setenquiryDetail] = useState({});
  //   const router = useRouter();
  const logindata = useSelector((state) => state.loginreducer);
  const history = useNavigate();

  const getData = async () => {
    setIsLoading(true);
    const data = await ApiHelper.getproductEnquiryByUserId(logindata.data?.id,logindata.data?.accessToken)
    const sortedItems = [...data].sort((a, b) => b.productEnquiryId - a.productEnquiryId);
    console.log(data);

    setenquiryList(sortedItems);
       setIsLoading(false);
  };
  useEffect(() => {
    if(logindata.data.length!==0 && logindata.data?.roles[0] === "ROLE_USER"){
      window.scroll(0,0);
      getData();
    }
    else{
     history("/login")
    }
   
  }, [])
 
  
  const updateParentState = (childData, enquiryId, row) => {
    setOpenModal(childData);
    setenquiryId(enquiryId);
    setenquiryDetail(row);
  };
  return (
    <>
      
      <TopNav />
      {isLoading ? (
                  <span style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "50vh",
                  }}>
                    <Spinner />
                  </span>
                ):
      <div className="content-wrapper">
        <Container className="pt-4">
          <Box component="main">
            <Container className="vendor-box">
              <div className="two pb-3">
                <h2 className="form-heading">
                  Product's Enquiry List
                  {/* <span>Enquiries by customers</span> */}
                </h2>
              </div>


              <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                  <TableHead className="mui-tableHead">
                    <TableRow>
                      <TableCell />
                      <TableCell>Enquiry No</TableCell>
                      <TableCell>Enquiry Date</TableCell>
                      <TableCell>Full Name</TableCell>
                      {/* <TableCell>Email Id</TableCell>
                      <TableCell align="right">Contact No.</TableCell> */}
                      <TableCell>Status</TableCell>

                      <TableCell>View</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {openModal === false &&
                    enquiryList.length!==0?
                      enquiryList.map((row) => (
                        <Row
                          key={row.productEnquiryId}
                          row={row}
                          updateParentState={updateParentState}
                        />
                      )):"no data found"}
                  </TableBody>
                </Table>
              </TableContainer>

             
            </Container>
          </Box>
        </Container>
      </div>}
    
    </>
  );
};

export default ProductEnquiries;
